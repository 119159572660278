import React from "react";
import { useSelector } from "react-redux";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  Svg,
  Line,
} from "@react-pdf/renderer";

import Button from "@mui/material/Button";

import {
  createTableHeaderA4,
  createTableRowA4,
  tableStyleA4,
} from "../Parts/TableA4";
import {
  createTableHeader_E_F_20_20_40_20,
  createTableHeader_head_F,
  createTableRow_5_12_22_41_5_5_5_5,
  createTableRow_5_34_41_5_5_5_5,
  createTableRow_100,
  createTableRow_5_25_30_30_10,
  createTableRow_5_25_30_30_10_header,
  tableStyle_2,
  tableStyle_18,
} from "../Parts/CreateTable";

import fontRegular from "../fonts/Nasu-Regular.ttf"; //ttfファイル参照
import fontBold from "../fonts/Nasu-Bold.ttf"; //ttfファイル参照

const PdfFireEquipment = () => {
  const state = useSelector((state) => state["FireEquipment"]["B1"]);

  const states = useSelector((state) => state["FireEquipment"]);

  const buldingNumberFireEquipment = state["f0"]["f01_ro2"];

  const CheackInvestFireDoor = state["f2"]["f25_ro1_1"];
  const CheackInvestFireShutter = state["f2"]["f25_ro2_1"];
  const CheackInvestFireProtectionCrossScreen = state["f2"]["f25_ro3_1"];
  const CheackInvestDrencher = state["f2"]["f25_ro4_1"];

  Font.registerHyphenationCallback((word) =>
    word.length === 1 ? [word] : Array.from(word).map((char) => char)
  );

  const Page1 = ({ state, state0, state9 }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1}>
          <Text style={styles.text1}>
            三十六号の八様式（第六条関係）（Ａ４）
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>定期検査報告書</Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>（防火設備）</Text>
        </View>
        <View style={styles.section_6}>
          {!!state0.f01_i1 && (
            <Text style={styles.text1}>
              台帳番号 {state0.f01_i1}-{state0.f01_i2}
            </Text>
          )}
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>（第一面）</Text>
        </View>
        <View style={styles.section_3}>
          <Text style={styles.text1}>
            　建築基準法第12条第３項の規定により、定期検査の結果を報告します。この報告書に記載の事項は事実に相違あり
          </Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>ません。</Text>
        </View>
        <View style={styles.section_4}>
          {!!state9.f91_1 && <Text style={styles.text1}>{state9.f91_1}様</Text>}
        </View>
        <View style={styles.section_5}>
          {!!state0.f01_ho_1 && (
            <Text style={styles.text1}>
              {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                era: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              }).format(new Date(state0.f01_ho_1))}
            </Text>
          )}
        </View>
        <View style={styles.section_5}>
          {!!state0.f01_ha && (
            <Text style={styles.text1}>報告者氏名 {state0.f01_ha}</Text>
          )}
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_5}>
          {!!state0.f01_ni && (
            <Text style={styles.text1}>検査者氏名 {state0.f01_ni}</Text>
          )}
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【1.所有者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】
            </Text>
            {!!state.f11_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.f11_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.f11_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.f11_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ホ．電話番号】　
            </Text>
            {!!state.f11_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【2.管理者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】　
            </Text>
            {!!state.f12_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.f12_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.f12_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.f12_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．電話番号】</Text>
            {!!state.f12_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【3.報告対象建築物】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．所在地】</Text>
            {!!state.f13_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．名称のフリガナ】
            </Text>
            {!!state.f13_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．名称】　</Text>
            {!!state.f13_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．用途】　</Text>
            {!!state.f13_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_ni}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【4.検査による指摘の概要】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            {!!state.f14_i1 && <Text style={styles.text1}>{state.f14_i1}</Text>}
            {!!state.f14_i2 && <Text style={styles.text1}>（既存不適格）</Text>}
          </View>
          <View style={styles.boxContainer}>
            {!!state.f14_ro && (
              <Text style={[styles.text1, { maxWidth: 545 }]}>
                {state.f14_ro}
              </Text>
            )}
          </View>
        </View>
      </Page>
    );
  };

  const Page2 = ({ state, bNumber, state9 }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1_1}>
          <Text
            style={styles.text1}
          >{`(第二面) 第${bNumber}棟 ${state9.f92_1}`}</Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>防火設備の状況等</Text>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【1.建築物の概要】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．階数】</Text>
            {!!state.f21_i1 && (
              <Text style={[styles.text1, { width: 100 }]}>
                地上{state.f21_i1}階
              </Text>
            )}
            {!!state.f21_i2 && (
              <Text style={[styles.text1, { width: 100 }]}>
                地下{state.f21_i2}階
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．建築面積】</Text>
            {!!state.f21_ro && (
              <Text style={styles.text1}>{state.f21_ro}㎡</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．延べ面積】</Text>
            {!!state.f21_ha && (
              <Text style={styles.text1}>{state.f21_ha}㎡</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【2.確認済証交付年月日等】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 150 }]}>
              【イ．確認済証交付年月日】
            </Text>
            {!!state.f22_i1_1 && (
              <Text style={[styles.text1, { width: 95 }]}>
                {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                  era: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(state.f22_i1_1))}
              </Text>
            )}
            {!!state.f22_i2 && (
              <Text
                style={[styles.text1, { width: 300 }]}
              >{`第${state.f22_i2}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 150 }]}>
              【ロ．確認済証交付者】
            </Text>
            {!!state.f22_ro1 && (
              <Text style={[styles.text1, { width: 95 }]}>{state.f22_ro1}</Text>
            )}
            {!!state.f22_ro2 && (
              <Text
                style={[styles.text1, { width: 300 }]}
              >{`（${state.f22_ro2}）`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 150 }]}>
              【ハ．検査済証交付年月日】
            </Text>
            {!!state.f22_ha1_1 && (
              <Text style={[styles.text1, { width: 95 }]}>
                {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                  era: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(state.f22_ha1_1))}
              </Text>
            )}
            {!!state.f22_ha2 && (
              <Text style={[styles.text1, { width: 300 }]}>
                {`第${state.f22_ha2}号`}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 150 }]}>
              【ニ．検査済証交付者】
            </Text>
            {!!state.f22_ni1 && (
              <Text style={[styles.text1, { width: 95 }]}>{state.f22_ni1}</Text>
            )}
            {!!state.f22_ni2 && (
              <Text
                style={[styles.text1, { width: 300 }]}
              >{`（${state.f22_ni2}）`}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【3.検査日等】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}>
              【イ．今回の検査】
            </Text>
            {!!state.f23_i_1 && (
              <Text style={styles.text1}>{`${state.f23_i_1}実施`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}>
              【ロ．前回の検査】
            </Text>
            {!!state.f23_ro1 && (
              <Text style={[styles.text1, { width: 50 }]}>{state.f23_ro1}</Text>
            )}
            {!!state.f23_ro2 && (
              <Text style={styles.text1}>{`（${state.f23_ro2}報告）`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}>
              【ハ．前回の検査に関する書類の写し】
            </Text>
            {!!state.f23_ha && (
              <Text style={[styles.text1, { width: 50 }]}>{state.f23_ha}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【4.防火設備の検査者】</Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>（代表となる検査者）</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
            {!!state.f24_ai1 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.f24_ai1}
              </Text>
            )}
            {!!state.f24_ai2 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.f24_ai2}
              </Text>
            )}
            {!!state.f24_ai3 && (
              <Text style={styles.text1}>{`第${state.f24_ai3}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
            {!!state.f24_ai4 && (
              <Text style={styles.text1}>{`第${state.f24_ai4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．氏名のフリガナ】
            </Text>
            {!!state.f24_aro && (
              <Text style={styles.text1}>{state.f24_aro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
            {!!state.f24_aha && (
              <Text style={styles.text1}>{state.f24_aha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
            {!!state.f24_ani1 && (
              <Text style={styles.text1}>{state.f24_ani1}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            {!!state.f24_ani2 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.f24_ani2}
              </Text>
            )}
            {!!state.f24_ani3 && (
              <Text style={[styles.text1, { width: 100 }]}>
                {state.f24_ani3}知事登録
              </Text>
            )}
            {!!state.f24_ani4 && (
              <Text style={styles.text1}>{`第${state.f24_ani4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．郵便番号】</Text>
            {!!state.f24_aho && (
              <Text style={styles.text1}>{state.f24_aho}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ヘ．所在地】　</Text>
            {!!state.f24_ahe && (
              <Text style={styles.text1}>{state.f24_ahe}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ト．電話番号】　
            </Text>
            {!!state.f24_ato && (
              <Text style={styles.text1}>{state.f24_ato}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>（その他の検査者）</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
            {!!state.f24_bi1 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.f24_bi1}
              </Text>
            )}
            {!!state.f24_bi2 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.f24_bi2}
              </Text>
            )}
            {!!state.f24_bi3 && (
              <Text style={styles.text1}>{`第${state.f24_bi3}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            <Text style={[styles.text1, { width: 180 }]}>防火設備検査員</Text>
            {!!state.f24_bi4 && (
              <Text style={styles.text1}>{`第${state.f24_bi4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．氏名のフリガナ】
            </Text>
            {!!state.f24_bro && (
              <Text style={styles.text1}>{state.f24_bro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
            {!!state.f24_bha && (
              <Text style={styles.text1}>{state.f24_bha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
            {!!state.f24_bni1 && (
              <Text style={styles.text1}>{state.f24_bni1}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            {!!state.f24_bni2 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.f24_bni2}
              </Text>
            )}
            {!!state.f24_bni3 && (
              <Text style={[styles.text1, { width: 100 }]}>
                {state.f24_bni3}知事登録
              </Text>
            )}
            {!!state.f24_bni4 && (
              <Text style={styles.text1}>{`第${state.f24_bni4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．郵便番号】</Text>
            {!!state.f24_bho && (
              <Text style={styles.text1}>{state.f24_bho}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ヘ．所在地】　</Text>
            {!!state.f24_bhe && (
              <Text style={styles.text1}>{state.f24_bhe}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ト．電話番号】　
            </Text>
            {!!state.f24_bto && (
              <Text style={styles.text1}>{state.f24_bto}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={[styles.text1, { width: 150 }]}>
            【5.防火設備の概要】
          </Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}>
              【イ．避難安全検証法等の適用】
            </Text>
            {!!state.f25_i1_1 && (
              <Text style={styles.text1}>区画避難安全検証法</Text>
            )}
            {!!state.f25_i1_2 && (
              <Text style={styles.text1}>{`（${state.f25_i1_2}階）`}</Text>
            )}

            {!!state.f25_i2_1 && (
              <Text style={styles.text1}>階避難安全検証法</Text>
            )}
            {!!state.f25_i2_2 && (
              <Text style={styles.text1}>{`（${state.f25_i2_2}階）`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}></Text>
            {!!state.f25_i3_1 && (
              <Text style={styles.text1}>全館避難安全検証法</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}></Text>
            {!!state.f25_i4_1 && (
              <Text style={[styles.text1, { width: 45 }]}>その他</Text>
            )}
            {!!state.f25_i4_2 && (
              <Text
                style={[styles.text1, { width: 300 }]}
              >{`（${state.f25_i4_2}）`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}>【ロ．防火設備】</Text>
            {!!state.f25_ro1_1 && (
              <Text style={styles.text1}>{`防火扉　`}</Text>
            )}
            {!!state.f25_ro1_2 && (
              <Text style={styles.text1}>{`（${state.f25_ro1_2}枚）`}</Text>
            )}

            {!!state.f25_ro2_1 && (
              <Text style={styles.text1}>{`防火シャッター　`}</Text>
            )}
            {!!state.f25_ro2_2 && (
              <Text style={styles.text1}>{`（${state.f25_ro2_2}枚）`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}></Text>
            {!!state.f25_ro3_1 && (
              <Text style={styles.text1}>{`耐火クロススクリーン　`}</Text>
            )}
            {!!state.f25_ro3_2 && (
              <Text style={styles.text1}>{`（${state.f25_ro3_2}枚）`}</Text>
            )}
            {!!state.f25_ro4_1 && (
              <Text style={styles.text1}>{`ドレンチャー　`}</Text>
            )}
            {!!state.f25_ro4_2 && (
              <Text style={styles.text1}>{`（${state.f25_ro4_2}台）`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 200 }]}></Text>
            {!!state.f25_ro5_1 && <Text style={styles.text1}>その他</Text>}
            {!!state.f25_ro5_2 && (
              <Text style={styles.text1}>{`（${state.f25_ro5_2}台）`}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【6.防火設備の検査の状況】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．指摘の内容】
            </Text>
            {!!state.f26_i1 && <Text style={styles.text1}>{state.f26_i1}</Text>}
            {!!state.f26_i2 && <Text style={styles.text1}>（既存不適格）</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．指摘の概要】　
            </Text>
            {!!state.f26_ro && (
              <Text style={[styles.text1, { maxWidth: 415 }]}>
                {state.f26_ro}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．改善予定の有無】
            </Text>
            {!!state.f26_ha1 && (
              <Text style={styles.text1}>{state.f26_ha1}</Text>
            )}
            {!!state.f26_ha2_1 && (
              <Text style={styles.text1}>{`(${state.f26_ha2_1.slice(
                0,
                -2
              )}月に改善予定)`}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【7.防火設備の不具合の発生状況】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．不具合】</Text>
            {!!state.f27_i && <Text style={styles.text1}>{state.f27_i}</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．不具合記録】
            </Text>
            {!!state.f27_ro && <Text style={styles.text1}>{state.f27_ro}</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．改善の状況】
            </Text>
            {!!state.f27_ha1 && (
              <Text style={styles.text1}>{state.f27_ha1}</Text>
            )}
            {!!state.f27_ha2_1 && (
              <Text style={styles.text1}>{`(${state.f27_ha2_1.slice(
                0,
                -2
              )}月に改善予定)`}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={[styles.text1, { width: 150 }]}>【8.備考】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            {!!state.f28_1 && (
              <Text style={[styles.text1, { width: 545 }]}>{state.f28_1}</Text>
            )}
          </View>
        </View>
      </Page>
    );
  };

  const Page3 = ({ state }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1_1}>
          <Text style={styles.text1}>(第三面)</Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>防火設備に係る不具合の状況</Text>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={tableStyleA4}>
          {createTableHeaderA4()}
          {createTableRowA4(
            state.f3_1_11_1,
            state.f3_1_12,
            state.f3_1_13,
            state.f3_1_14_1,
            state.f3_1_15
          )}
          {createTableRowA4(
            state.f3_1_21_1,
            state.f3_1_22,
            state.f3_1_23,
            state.f3_1_24_1,
            state.f3_1_25
          )}
          {createTableRowA4(
            state.f3_1_31_1,
            state.f3_1_32,
            state.f3_1_33,
            state.f3_1_34_1,
            state.f3_1_35
          )}
        </View>
      </Page>
    );
  };

  const PageInvestFireDoor = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記第一号（A４)　</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>検査結果表</Text>
          </View>
          <View style={styles.section_2}>
            <Text
              style={styles.text1}
            >{`(防火扉) 第${bNumber}棟 ${state9.f92_1}`}</Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_E_F_20_20_40_20(
              state.f40_1_11,
              state.f40_1_12,
              state.f40_1_21,
              state.f40_1_22,
              state.f40_1_31,
              state.f40_1_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_F()}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `防火扉`,
              `設置場所の周囲状況`,
              `閉鎖の障害となる物品の放置の状況`,
              state.f41_1_1_11,
              state.f41_1_1_12,
              state.f41_1_1_13,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              "",
              "扉、枠及び金物",
              "扉の取付けの状況",
              state.f41_1_1_21,
              state.f41_1_1_22,
              state.f41_1_1_23,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              ``,
              `扉、枠及び金物の劣化及び損傷の状況`,
              state.f41_1_1_31,
              state.f41_1_1_32,
              state.f41_1_1_33,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              `危害防止装置`,
              `作動の状況`,
              state.f41_1_1_41,
              state.f41_1_1_42,
              state.f41_1_1_43,
              1,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              `連動機構`,
              `煙感知器、熱煙複合式感知器`,
              `設置位置`,
              state.f41_1_1_51,
              state.f41_1_1_52,
              state.f41_1_1_53,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              `及び熱感知器`,
              `風道の材質`,
              state.f41_1_1_61,
              state.f41_1_1_62,
              state.f41_1_1_63,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              `温度ヒューズ装置`,
              `設置の状況`,
              state.f41_1_1_71,
              state.f41_1_1_72,
              state.f41_1_1_73,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              `連動制御器`,
              `スイッチ類及び表示灯の状況`,
              state.f41_1_1_81,
              state.f41_1_1_82,
              state.f41_1_1_83,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              ``,
              `結線接続の状況`,
              state.f41_1_1_91,
              state.f41_1_1_92,
              state.f41_1_1_93,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              ``,
              `接地の状況`,
              state.f41_1_1_101,
              state.f41_1_1_102,
              state.f41_1_1_103,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              ``,
              ``,
              `予備電源への切り替えの状況`,
              state.f41_1_1_111,
              state.f41_1_1_112,
              state.f41_1_1_113,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              ``,
              `連動機構用予備電源`,
              `劣化及び損傷の状況`,
              state.f41_1_1_121,
              state.f41_1_1_122,
              state.f41_1_1_123,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              ``,
              `容量の状況`,
              state.f41_1_1_131,
              state.f41_1_1_132,
              state.f41_1_1_133,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              `自動閉鎖装置`,
              `設置の状況`,
              state.f41_1_1_141,
              state.f41_1_1_142,
              state.f41_1_1_143,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              ``,
              ``,
              `再ロック防止機構の作動の状況`,
              state.f41_1_1_151,
              state.f41_1_1_152,
              state.f41_1_1_153,
              1,
              1
            )}
            {createTableRow_5_34_41_5_5_5_5(
              "(16)",
              `総合的な作動の状況`,
              `防火扉の閉鎖の状況`,
              state.f41_1_1_161,
              state.f41_1_1_162,
              state.f41_1_1_163,
              0
            )}
            {createTableRow_5_34_41_5_5_5_5(
              "(17)",
              ``,
              `防火区画の形成の状況`,
              state.f41_1_1_171,
              state.f41_1_1_172,
              state.f41_1_1_173,
              1
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "検査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等",
              "改善(予定)年月"
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_1_3_11,
              state.f41_1_3_12,
              state.f41_1_3_13,
              state.f41_1_3_14,
              state.f41_1_3_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_1_3_21,
              state.f41_1_3_22,
              state.f41_1_3_23,
              state.f41_1_3_24,
              state.f41_1_3_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_1_3_31,
              state.f41_1_3_32,
              state.f41_1_3_33,
              state.f41_1_3_34,
              state.f41_1_3_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_1_3_41,
              state.f41_1_3_42,
              state.f41_1_3_43,
              state.f41_1_3_44,
              state.f41_1_3_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_1_3_51,
              state.f41_1_3_52,
              state.f41_1_3_53,
              state.f41_1_3_54,
              state.f41_1_3_55_1
            )}
          </View>
        </Page>
      </>
    );
  };

  const PageInvestFireShutter = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記第二号（A４)　</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>検査結果表</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>
              {`(防火シャッター) 第${bNumber}棟 ${state9.f92_1}`}
            </Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_E_F_20_20_40_20(
              state.f40_2_11,
              state.f40_2_12,
              state.f40_2_21,
              state.f40_2_22,
              state.f40_2_31,
              state.f40_2_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_F()}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `防火シャッター`,
              `設置場所の周囲状況`,
              `閉鎖の障害となる物品の放置の状況`,
              state.f41_2_1_11,
              state.f41_2_1_12,
              state.f41_2_1_13,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              "",
              "駆動装置",
              `軸受け部のブラケット、巻取りシャフト及び開閉機
              の取付けの状況※`,
              state.f41_2_1_21,
              state.f41_2_1_22,
              state.f41_2_1_23,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              ``,
              `スプロケットの設置の状況※`,
              state.f41_2_1_31,
              state.f41_2_1_32,
              state.f41_2_1_33,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              ``,
              `軸受け部のブラケット、ベアリング及びスプロケット
              又はロープ車の劣化及び損傷の状況※`,
              state.f41_2_1_41,
              state.f41_2_1_42,
              state.f41_2_1_43,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              ``,
              `ローラチェーン又はワイヤーロープの劣化及び損傷の状況`,
              state.f41_2_1_51,
              state.f41_2_1_52,
              state.f41_2_1_53,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              `カーテン部 `,
              `スラット及び座板の劣化等の状況`,
              state.f41_2_1_61,
              state.f41_2_1_62,
              state.f41_2_1_63,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              ``,
              `吊り元の劣化及び損傷並びに固定の状況`,
              state.f41_2_1_71,
              state.f41_2_1_72,
              state.f41_2_1_73,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              `ケース`,
              `劣化及び損傷の状況`,
              state.f41_2_1_81,
              state.f41_2_1_82,
              state.f41_2_1_83,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              `まぐさ及びガイドレール`,
              `劣化及び損傷の状況`,
              state.f41_2_1_91,
              state.f41_2_1_92,
              state.f41_2_1_93,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              `危害防止装置`,
              `危害防止用連動中継器の配線の状況`,
              state.f41_2_1_101,
              state.f41_2_1_102,
              state.f41_2_1_103,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              ``,
              ``,
              `危害防止装置用予備電源の劣化及び損傷の状況`,
              state.f41_2_1_111,
              state.f41_2_1_112,
              state.f41_2_1_113,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              ``,
              ``,
              `危害防止装置用予備電源の容量の状況`,
              state.f41_2_1_121,
              state.f41_2_1_122,
              state.f41_2_1_123,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              ``,
              `座板感知部の劣化及び損傷並びに作動の状況`,
              state.f41_2_1_131,
              state.f41_2_1_132,
              state.f41_2_1_133,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              ``,
              `作動の状況`,
              state.f41_2_1_141,
              state.f41_2_1_142,
              state.f41_2_1_143,
              1,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              `連動機構`,
              `煙感知器、熱煙複合式感知器`,
              `設置位置`,
              state.f41_2_1_151,
              state.f41_2_1_152,
              state.f41_2_1_153,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(16)",
              ``,
              `及び熱感知器`,
              `感知の状況`,
              state.f41_2_1_161,
              state.f41_2_1_162,
              state.f41_2_1_163,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(17)",
              ``,
              `温度ヒューズ装置`,
              `設置の状況`,
              state.f41_2_1_171,
              state.f41_2_1_172,
              state.f41_2_1_173,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(18)",
              ``,
              `連動制御器`,
              `スイッチ類及び表示灯の状況`,
              state.f41_2_1_181,
              state.f41_2_1_182,
              state.f41_2_1_183,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(19)",
              ``,
              ``,
              `結線接続の状況`,
              state.f41_2_1_191,
              state.f41_2_1_192,
              state.f41_2_1_193,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(20)",
              ``,
              ``,
              `接地の状況`,
              state.f41_2_1_201,
              state.f41_2_1_202,
              state.f41_2_1_203,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(21)",
              ``,
              ``,
              `予備電源への切り替えの状況`,
              state.f41_2_1_211,
              state.f41_2_1_212,
              state.f41_2_1_213,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(22)",
              ``,
              `連動機構用予備電源`,
              `劣化及び損傷の状況`,
              state.f41_2_1_221,
              state.f41_2_1_222,
              state.f41_2_1_223,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(23)",
              ``,
              ``,
              `容量の状況`,
              state.f41_2_1_231,
              state.f41_2_1_232,
              state.f41_2_1_233,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(24)",
              ``,
              `自動閉鎖装置`,
              `設置の状況`,
              state.f41_2_1_241,
              state.f41_2_1_242,
              state.f41_2_1_243,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(25)",
              ``,
              `手動閉鎖装置`,
              `設置の状況`,
              state.f41_2_1_251,
              state.f41_2_1_252,
              state.f41_2_1_253,
              1,
              1
            )}

            {createTableRow_5_34_41_5_5_5_5(
              "(26)",
              `総合的な作動の状況`,
              `防火シャッターの閉鎖の状況`,
              state.f41_2_1_261,
              state.f41_2_1_262,
              state.f41_2_1_263,
              0
            )}
            {createTableRow_5_34_41_5_5_5_5(
              "(27)",
              ``,
              `防火区画の形成の状況`,
              state.f41_2_1_271,
              state.f41_2_1_272,
              state.f41_2_1_273,
              1
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "検査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等",
              "改善(予定)年月"
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_2_3_11,
              state.f41_2_3_12,
              state.f41_2_3_13,
              state.f41_2_3_14,
              state.f41_2_3_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_2_3_21,
              state.f41_2_3_22,
              state.f41_2_3_23,
              state.f41_2_3_24,
              state.f41_2_3_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_2_3_31,
              state.f41_2_3_32,
              state.f41_2_3_33,
              state.f41_2_3_34,
              state.f41_2_3_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_2_3_41,
              state.f41_2_3_42,
              state.f41_2_3_43,
              state.f41_2_3_44,
              state.f41_2_3_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_2_3_51,
              state.f41_2_3_52,
              state.f41_2_3_53,
              state.f41_2_3_54,
              state.f41_2_3_55_1
            )}
          </View>
        </Page>
      </>
    );
  };

  const PageInvestFireProtectionCrossScreen = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記第三号（A４)</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>検査結果表</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>
              {`(耐火クロススクリーン) 第${bNumber}棟 ${state9.f92_1}`}
            </Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_E_F_20_20_40_20(
              state.f40_3_11,
              state.f40_3_12,
              state.f40_3_21,
              state.f40_3_22,
              state.f40_3_31,
              state.f40_3_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_F()}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `耐火クロス`,
              `設置場所の周囲状況`,
              `閉鎖の障害となる物品の放置の状況`,
              state.f41_3_1_11,
              state.f41_3_1_12,
              state.f41_3_1_13,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              `スクリーン`,
              `駆動装置`,
              `ローラチェーンの劣化及び損傷の状況`,
              state.f41_3_1_21,
              state.f41_3_1_22,
              state.f41_3_1_23,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              `カーテン部`,
              `耐火クロス及び座板の劣化及び損傷の状況`,
              state.f41_3_1_31,
              state.f41_3_1_32,
              state.f41_3_1_33,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              ``,
              `吊り元の劣化及び損傷並びに固定の状況`,
              state.f41_3_1_41,
              state.f41_3_1_42,
              state.f41_3_1_43,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              `ケース`,
              `劣化及び損傷の状況`,
              state.f41_3_1_51,
              state.f41_3_1_52,
              state.f41_3_1_53,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              `まぐさ及びガイドレール`,
              `劣化及び損傷の状況`,
              state.f41_3_1_61,
              state.f41_3_1_62,
              state.f41_3_1_63,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              `危害防止装置`,
              `危害防止用連動中継器の配線の状況`,
              state.f41_3_1_71,
              state.f41_3_1_72,
              state.f41_3_1_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `危害防止装置用予備電源の劣化及び損傷の状況`,
              state.f41_3_1_81,
              state.f41_3_1_82,
              state.f41_3_1_83,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              ``,
              `危害防止装置用予備電源の容量の状況`,
              state.f41_3_1_91,
              state.f41_3_1_92,
              state.f41_3_1_93,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              ``,
              `座板感知部の劣化及び損傷並びに作動の状況`,
              state.f41_3_1_101,
              state.f41_3_1_102,
              state.f41_3_1_103,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              ``,
              ``,
              `作動の状況`,
              state.f41_3_1_111,
              state.f41_3_1_112,
              state.f41_3_1_113,
              1,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              `連動機構`,
              `煙感知器、熱煙複合式感知器`,
              `設置位置`,
              state.f41_3_1_121,
              state.f41_3_1_122,
              state.f41_3_1_123,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              `及び熱感知器`,
              `感知の状況`,
              state.f41_3_1_131,
              state.f41_3_1_132,
              state.f41_3_1_133,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              `連動制御器`,
              `スイッチ類及び表示灯の状況`,
              state.f41_3_1_141,
              state.f41_3_1_142,
              state.f41_3_1_143,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              ``,
              ``,
              `結線接続の状況`,
              state.f41_3_1_151,
              state.f41_3_1_152,
              state.f41_3_1_153,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(16)",
              ``,
              ``,
              `接地の状況`,
              state.f41_3_1_161,
              state.f41_3_1_162,
              state.f41_3_1_163,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(17)",
              ``,
              ``,
              `予備電源への切り替えの状況`,
              state.f41_3_1_171,
              state.f41_3_1_172,
              state.f41_3_1_173,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(18)",
              ``,
              `連動機構用予備電源`,
              `劣化及び損傷の状況`,
              state.f41_3_1_181,
              state.f41_3_1_182,
              state.f41_3_1_183,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(19)",
              ``,
              ``,
              `容量の状況`,
              state.f41_3_1_191,
              state.f41_3_1_192,
              state.f41_3_1_193,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(20)",
              ``,
              `自動閉鎖装置`,
              `設置の状況`,
              state.f41_3_1_201,
              state.f41_3_1_202,
              state.f41_3_1_203,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(21)",
              ``,
              `手動閉鎖装置`,
              `設置の状況`,
              state.f41_3_1_211,
              state.f41_3_1_212,
              state.f41_3_1_213,
              1,
              1
            )}
            {createTableRow_5_34_41_5_5_5_5(
              "(22)",
              `総合的な作動の状況`,
              `耐火クロススクリーンの閉鎖の状況`,
              state.f41_3_1_221,
              state.f41_3_1_222,
              state.f41_3_1_223,
              0
            )}
            {createTableRow_5_34_41_5_5_5_5(
              "(23)",
              ``,
              `防火区画の形成の状況`,
              state.f41_3_1_231,
              state.f41_3_1_232,
              state.f41_3_1_233,
              1
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "検査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等",
              "改善(予定)年月"
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_3_3_11,
              state.f41_3_3_12,
              state.f41_3_3_13,
              state.f41_3_3_14,
              state.f41_3_3_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_3_3_21,
              state.f41_3_3_22,
              state.f41_3_3_23,
              state.f41_3_3_24,
              state.f41_3_3_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_3_3_31,
              state.f41_3_3_32,
              state.f41_3_3_33,
              state.f41_3_3_34,
              state.f41_3_3_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_3_3_41,
              state.f41_3_3_42,
              state.f41_3_3_43,
              state.f41_3_3_44,
              state.f41_3_3_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_3_3_51,
              state.f41_3_3_52,
              state.f41_3_3_53,
              state.f41_3_3_54,
              state.f41_3_3_55_1
            )}
          </View>
        </Page>
      </>
    );
  };

  const PageInvestDrencher = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記第四号（A４)</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>検査結果表</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>
              {`(ドレンチャーその他の水幕を形成する防火設備) 第${bNumber}棟 ${state9.f92_1}`}
            </Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_E_F_20_20_40_20(
              state.f40_4_11,
              state.f40_4_12,
              state.f40_4_21,
              state.f40_4_22,
              state.f40_4_31,
              state.f40_4_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_F()}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `ドレンチャー等`,
              `設置場所の周囲状況`,
              `作動の障害となる物品の放置の状況`,
              state.f41_4_1_11,
              state.f41_4_1_12,
              state.f41_4_1_13,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              ``,
              `散水ヘッド `,
              `散水ヘッドの設置の状況`,
              state.f41_4_1_21,
              state.f41_4_1_22,
              state.f41_4_1_23,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              `開閉弁`,
              `開閉弁の状況`,
              state.f41_4_1_31,
              state.f41_4_1_32,
              state.f41_4_1_33,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              `排水設備`,
              `排水の状況`,
              state.f41_4_1_41,
              state.f41_4_1_42,
              state.f41_4_1_43,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              `水源`,
              `貯水槽の劣化及び損傷、水質並びに水量の状況`,
              state.f41_4_1_51,
              state.f41_4_1_52,
              state.f41_4_1_53,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              ``,
              `給水装置の状況`,
              state.f41_4_1_61,
              state.f41_4_1_62,
              state.f41_4_1_63,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              `加圧送水装置`,
              `ポンプ制御盤のスイッチ類及び表示灯の状況`,
              state.f41_4_1_71,
              state.f41_4_1_72,
              state.f41_4_1_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `結線接続の状況`,
              state.f41_4_1_81,
              state.f41_4_1_82,
              state.f41_4_1_83,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              ``,
              `接地の状況`,
              state.f41_4_1_91,
              state.f41_4_1_92,
              state.f41_4_1_93,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              ``,
              `ポンプ及び電動機の状況`,
              state.f41_4_1_101,
              state.f41_4_1_102,
              state.f41_4_1_103,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              ``,
              ``,
              `加圧送水装置用予備電源への切り替えの状況`,
              state.f41_4_1_111,
              state.f41_4_1_112,
              state.f41_4_1_113,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              ``,
              ``,
              `加圧送水装置用予備電源の劣化及び損傷の状況`,
              state.f41_4_1_121,
              state.f41_4_1_122,
              state.f41_4_1_123,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              ``,
              `加圧送水装置用予備電源の容量の状況`,
              state.f41_4_1_131,
              state.f41_4_1_132,
              state.f41_4_1_133,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              ``,
              `圧力計、呼水槽、起動用圧力スイッチ等の付属装置の状況`,
              state.f41_4_1_141,
              state.f41_4_1_142,
              state.f41_4_1_143,
              1,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              `連動機構`,
              `煙感知器、熱煙複合式感知器`,
              `設置位置`,
              state.f41_4_1_151,
              state.f41_4_1_152,
              state.f41_4_1_153,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(16)",
              ``,
              `及び熱感知器`,
              `感知の状況`,
              state.f41_4_1_161,
              state.f41_4_1_162,
              state.f41_4_1_163,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(17)",
              ``,
              `制御盤`,
              `スイッチ類及び表示灯の状況`,
              state.f41_4_1_171,
              state.f41_4_1_172,
              state.f41_4_1_173,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(18)",
              ``,
              ``,
              `結線接続の状況`,
              state.f41_4_1_181,
              state.f41_4_1_182,
              state.f41_4_1_183,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(19)",
              ``,
              ``,
              `接地の状況`,
              state.f41_4_1_191,
              state.f41_4_1_192,
              state.f41_4_1_193,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(20)",
              ``,
              ``,
              `予備電源への切り替えの状況`,
              state.f41_4_1_201,
              state.f41_4_1_202,
              state.f41_4_1_203,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(21)",
              ``,
              `連動機構用予備電源`,
              `劣化及び損傷の状況`,
              state.f41_4_1_211,
              state.f41_4_1_212,
              state.f41_4_1_213,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(22)",
              ``,
              ``,
              `容量の状況`,
              state.f41_4_1_221,
              state.f41_4_1_222,
              state.f41_4_1_223,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(23)",
              ``,
              `自動作動装置`,
              `設置の状況`,
              state.f41_4_1_231,
              state.f41_4_1_232,
              state.f41_4_1_233,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(24)",
              ``,
              `手動作動装置`,
              `設置の状況`,
              state.f41_4_1_241,
              state.f41_4_1_242,
              state.f41_4_1_243,
              1,
              1
            )}
            {createTableRow_5_34_41_5_5_5_5(
              "(25)",
              `総合的な作動の状況`,
              `ドレンチャー等の作動の状況`,
              state.f41_4_1_251,
              state.f41_4_1_252,
              state.f41_4_1_253,
              0
            )}
            {createTableRow_5_34_41_5_5_5_5(
              "(26)",
              ``,
              `防火区画の形成の状況`,
              state.f41_4_1_261,
              state.f41_4_1_262,
              state.f41_4_1_263,
              1
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "検査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等",
              "改善(予定)年月"
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_4_3_11,
              state.f41_4_3_12,
              state.f41_4_3_13,
              state.f41_4_3_14,
              state.f41_4_3_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_4_3_21,
              state.f41_4_3_22,
              state.f41_4_3_23,
              state.f41_4_3_24,
              state.f41_4_3_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_4_3_31,
              state.f41_4_3_32,
              state.f41_4_3_33,
              state.f41_4_3_34,
              state.f41_4_3_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_4_3_41,
              state.f41_4_3_42,
              state.f41_4_3_43,
              state.f41_4_3_44,
              state.f41_4_3_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.f41_4_3_51,
              state.f41_4_3_52,
              state.f41_4_3_53,
              state.f41_4_3_54,
              state.f41_4_3_55_1
            )}
          </View>
        </Page>
      </>
    );
  };

  const MyDocAnnex = ({ state, bNumber, state9 }) => {
    const CheackInvestFireDoor_annex = state["f2"]["f25_ro1_1"];
    const CheackInvestFireShutter_annex = state["f2"]["f25_ro2_1"];
    const CheackInvestFireProtectionCrossScreen_annex =
      state["f2"]["f25_ro3_1"];
    const CheackInvestDrencher_annex = state["f2"]["f25_ro4_1"];

    return (
      <>
        <Page2 state={state.f2} bNumber={bNumber} state9={state9} />
        {CheackInvestFireDoor_annex && (
          <PageInvestFireDoor
            state={state.f41}
            bNumber={bNumber}
            state9={state9}
          />
        )}
        {CheackInvestFireShutter_annex && (
          <PageInvestFireShutter
            state={state.f42}
            bNumber={bNumber}
            state9={state9}
          />
        )}
        {CheackInvestFireProtectionCrossScreen_annex && (
          <PageInvestFireProtectionCrossScreen
            state={state.f43}
            bNumber={bNumber}
            state9={state9}
          />
        )}
        {CheackInvestDrencher_annex && (
          <PageInvestDrencher
            state={state.f44}
            bNumber={bNumber}
            state9={state9}
          />
        )}
      </>
    );
  };

  const Summary1 = ({ state, state3, stateS }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1}>
          <Text style={styles.text1}>
            三十六号の九様式（第六条、第六条の三、第十一条の四関係）（Ａ４）
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>定期検査報告概要書</Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>（防火設備）</Text>
        </View>
        <View style={styles.section_6}>
          {!!state.f01_i1 && (
            <Text style={styles.text1}>
              台帳番号 {state.f01_i1}-{state.f01_i2}
            </Text>
          )}
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>(第一面)</Text>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【1.所有者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】
            </Text>
            {!!state.f11_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.f11_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.f11_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.f11_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ホ．電話番号】　
            </Text>
            {!!state.f11_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f11_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【2.管理者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】　
            </Text>
            {!!state.f12_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.f12_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.f12_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.f12_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．電話番号】</Text>
            {!!state.f12_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f12_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【3.報告対象建築物】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．所在地】</Text>
            {!!state.f13_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．名称のフリガナ】
            </Text>
            {!!state.f13_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．名称】　</Text>
            {!!state.f13_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．用途】　</Text>
            {!!state.f13_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.f13_ni}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【4.検査による指摘の概要】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            {!!state.f14_i1 && <Text style={styles.text1}>{state.f14_i1}</Text>}
            {!!state.f14_i2 && <Text style={styles.text1}>（既存不適格）</Text>}
          </View>
          <View style={styles.boxContainer}>
            {!!state.f14_ro && (
              <Text style={[styles.text1, { maxWidth: 545 }]}>
                {state.f14_ro}
              </Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【5.不具合の発生状況】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．不具合】</Text>
            {!!stateS["fsummry5_i"] && (
              <Text style={[styles.text1, { width: 50 }]}>
                {stateS["fsummry5_i"]}
              </Text>
            )}
          </View>
        </View>

        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．不具合記録】
            </Text>
            {!!stateS["fsummry5_ro"] && (
              <Text style={[styles.text1, { width: 50 }]}>
                {stateS["fsummry5_ro"]}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．不具合の概要】
            </Text>
            {!!state3["f3_1_12"] && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["f3_1_12"]}
              </Text>
            )}
          </View>
        </View>
        {!!state3["f3_1_22"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["f3_1_22"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["f3_1_32"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["f3_1_32"]}
              </Text>
            </View>
          </View>
        )}
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ニ．改善の状況】
            </Text>
            {!!stateS["fsummry5_ni1"] && (
              <Text style={styles.text1}>{stateS["fsummry5_ni1"]}</Text>
            )}
            {!!stateS["fsummry5_ni2_1"] && (
              <Text style={styles.text1}>
                {`(${stateS["fsummry5_ni2_1"].slice(0, -2)}月に改善予定)`}
              </Text>
            )}
          </View>
        </View>
        {!!stateS["fsummry5_ni3"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {`(理由：${stateS["fsummry5_ni3"]})`}
              </Text>
            </View>
          </View>
        )}
      </Page>
    );
  };

  const Summary2 = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text
              style={styles.text1}
            >{`(第二面) 第${bNumber}棟 ${state9.f92_1}`}</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>防火設備の状況等</Text>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【1.建築物の概要】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．階数】</Text>
              {!!state.f21_i1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地上{state.f21_i1}階
                </Text>
              )}
              {!!state.f21_i2 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地下{state.f21_i2}階
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．建築面積】
              </Text>
              {!!state.f21_ro && (
                <Text style={styles.text1}>{state.f21_ro}㎡</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．延べ面積】
              </Text>
              {!!state.f21_ha && (
                <Text style={styles.text1}>{state.f21_ha}㎡</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【2.確認済証交付年月日等】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．確認済証交付年月日】
              </Text>
              {!!state.f22_i1_1 && (
                <Text style={[styles.text1, { width: 80 }]}>
                  {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.f22_i1_1))}
                </Text>
              )}
              {!!state.f22_i2 && (
                <Text style={[styles.text1, { width: 315 }]}>
                  {state.f22_i2}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．確認済証交付者】
              </Text>
              {!!state.f22_ro1 && (
                <Text style={[styles.text1, { width: 95 }]}>
                  {state.f22_ro1}
                </Text>
              )}
              {!!state.f22_ro2 && (
                <Text
                  style={[styles.text1, { width: 300 }]}
                >{`（${state.f22_ro2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ハ．検査済証交付年月日】
              </Text>
              {!!state.f22_ha1_1 && (
                <Text style={[styles.text1, { width: 80 }]}>
                  {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.f22_ha1_1))}
                </Text>
              )}
              {!!state.f22_ha2 && (
                <Text style={[styles.text1, { width: 315 }]}>
                  {state.f22_ha2}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ニ．検査済証交付者】
              </Text>
              {!!state.f22_ni1 && (
                <Text style={[styles.text1, { width: 95 }]}>
                  {state.f22_ni1}
                </Text>
              )}
              {!!state.f22_ni2 && (
                <Text
                  style={[styles.text1, { width: 300 }]}
                >{`（${state.f22_ni2}）`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【3.検査日等】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【イ．今回の検査】
              </Text>
              {!!state.f23_i_1 && (
                <Text style={styles.text1}>{`${state.f23_i_1}実施`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ロ．前回の検査】
              </Text>
              {!!state.f23_ro1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.f23_ro1}
                </Text>
              )}
              {!!state.f23_ro2 && (
                <Text style={styles.text1}>{`（${state.f23_ro2}報告）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ハ．前回の検査に関する書類の写し】
              </Text>
              {!!state.f23_ha && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.f23_ha}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【4.防火設備の検査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.f24_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.f24_ai1}
                </Text>
              )}
              {!!state.f24_ai2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.f24_ai2}
                </Text>
              )}
              {!!state.f24_ai3 && (
                <Text style={styles.text1}>{`第${state.f24_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.f24_ai4 && (
                <Text style={styles.text1}>{`第${state.f24_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.f24_aro && (
                <Text style={styles.text1}>{state.f24_aro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.f24_aha && (
                <Text style={styles.text1}>{state.f24_aha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.f24_ani1 && (
                <Text style={styles.text1}>{state.f24_ani1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.f24_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.f24_ani2}
                </Text>
              )}
              {!!state.f24_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.f24_ani3}知事登録
                </Text>
              )}
              {!!state.f24_ani4 && (
                <Text style={styles.text1}>{`第${state.f24_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.f24_aho && (
                <Text style={styles.text1}>{state.f24_aho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.f24_ahe && (
                <Text style={styles.text1}>{state.f24_ahe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.f24_ato && (
                <Text style={styles.text1}>{state.f24_ato}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.f24_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.f24_bi1}
                </Text>
              )}
              {!!state.f24_bi2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.f24_bi2}
                </Text>
              )}
              {!!state.f24_bi3 && (
                <Text style={styles.text1}>{`第${state.f24_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>防火設備検査員</Text>
              {!!state.f24_bi4 && (
                <Text style={styles.text1}>{`第${state.f24_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.f24_bro && (
                <Text style={styles.text1}>{state.f24_bro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.f24_bha && (
                <Text style={styles.text1}>{state.f24_bha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.f24_bni1 && (
                <Text style={styles.text1}>{state.f24_bni1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.f24_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.f24_bni2}
                </Text>
              )}
              {!!state.f24_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.f24_bni3}知事登録
                </Text>
              )}
              {!!state.f24_bni4 && (
                <Text style={styles.text1}>{`第${state.f24_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.f24_bho && (
                <Text style={styles.text1}>{state.f24_bho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.f24_bhe && (
                <Text style={styles.text1}>{state.f24_bhe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.f24_bto && (
                <Text style={styles.text1}>{state.f24_bto}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>
              【5.防火設備の概要】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【イ．避難安全検証法等の適用】
              </Text>
              {!!state.f25_i1_1 && (
                <Text style={styles.text1}>区画避難安全検証法</Text>
              )}
              {!!state.f25_i1_2 && (
                <Text style={styles.text1}>{`（${state.f25_i1_2}階）`}</Text>
              )}

              {!!state.f25_i2_1 && (
                <Text style={styles.text1}>階避難安全検証法</Text>
              )}
              {!!state.f25_i2_2 && (
                <Text style={styles.text1}>{`（${state.f25_i2_2}階）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}></Text>
              {!!state.f25_i3_1 && (
                <Text style={styles.text1}>全館避難安全検証法</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}></Text>
              {!!state.f25_i4_1 && (
                <Text style={[styles.text1, { width: 45 }]}>その他</Text>
              )}
              {!!state.f25_i4_2 && (
                <Text
                  style={[styles.text1, { width: 300 }]}
                >{`（${state.f25_i4_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ロ．防火設備】
              </Text>
              {!!state.f25_ro1_1 && (
                <Text style={styles.text1}>{`防火扉　`}</Text>
              )}
              {!!state.f25_ro1_2 && (
                <Text style={styles.text1}>{`（${state.f25_ro1_2}枚）`}</Text>
              )}

              {!!state.f25_ro2_1 && (
                <Text style={styles.text1}>{`防火シャッター　`}</Text>
              )}
              {!!state.f25_ro2_2 && (
                <Text style={styles.text1}>{`（${state.f25_ro2_2}枚）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}></Text>
              {!!state.f25_ro3_1 && (
                <Text style={styles.text1}>{`耐火クロススクリーン　`}</Text>
              )}
              {!!state.f25_ro3_2 && (
                <Text style={styles.text1}>{`（${state.f25_ro3_2}枚）`}</Text>
              )}
              {!!state.f25_ro4_1 && (
                <Text style={styles.text1}>{`ドレンチャー　`}</Text>
              )}
              {!!state.f25_ro4_2 && (
                <Text style={styles.text1}>{`（${state.f25_ro4_2}台）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}></Text>
              {!!state.f25_ro5_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.f25_ro5_2 && (
                <Text style={styles.text1}>{`（${state.f25_ro5_2}台）`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>【6.備考】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.f28_1 && (
                <Text style={[styles.text1, { width: 545 }]}>
                  {state.f28_1}
                </Text>
              )}
            </View>
          </View>
        </Page>
      </>
    );
  };

  const MyDoc = () => {
    const annexList = [];
    if (buldingNumberFireEquipment > 1) {
      for (let index = 2; index < buldingNumberFireEquipment + 1; index++) {
        annexList.push(
          <MyDocAnnex
            key={index}
            state={states[`B${index}`]}
            bNumber={index}
            state9={states[`B${index}`].f9}
          />
        );
      }
    }

    let summaryList = [];

    for (let index = 1; index < buldingNumberFireEquipment + 1; index++) {
      summaryList.push(
        <Summary2
          key={index}
          state={states[`B${index}`].f2}
          bNumber={index}
          state9={states[`B${index}`].f9}
        />
      );
    }

    return (
      <Document>
        <Page1 state={state.f1} state0={state.f0} state9={state.f9} />
        <Page2 state={state.f2} bNumber={1} state9={state.f9} />
        <Page3 state={state.f3} />
        {CheackInvestFireDoor && (
          <PageInvestFireDoor state={state.f41} bNumber={1} state9={state.f9} />
        )}
        {CheackInvestFireShutter && (
          <PageInvestFireShutter
            state={state.f42}
            bNumber={1}
            state9={state.f9}
          />
        )}
        {CheackInvestFireProtectionCrossScreen && (
          <PageInvestFireProtectionCrossScreen
            state={state.f43}
            bNumber={1}
            state9={state.f9}
          />
        )}
        {CheackInvestDrencher && (
          <PageInvestDrencher state={state.f44} bNumber={1} state9={state.f9} />
        )}
        {annexList}
        <Summary1 state={state.f1} state3={state.f3} stateS={state.fsummry} />
        {summaryList}
      </Document>
    );
  };

  // ttfファイルのフォント定義
  // フォント「ナス レギュラー」
  Font.register({
    family: "Nasu-Regular",
    src: fontRegular,
  });

  // フォント「ナス 太字」
  Font.register({
    family: "Nasu-Bold",
    src: fontBold,
  });

  // CSSスタイル定義
  const styles = StyleSheet.create({
    text1: {
      fontSize: "10.5pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 1,
    },
    text8: {
      fontSize: "8pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 1,
    },
    textTable: {
      textAlign: "center",
      fontSize: "10.5pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 2,
    },

    section_1: {
      textAlign: "left",
      marginTop: 18,
      marginLeft: 20,
      marginRight: 20,
    },
    section_1_1: {
      textAlign: "center",
      marginTop: 18,
      marginLeft: 20,
      marginRight: 20,
    },
    section_2: {
      textAlign: "center",
      marginHorizontal: 20,
      marginVertical: 0,
    },
    section_3: {
      textAlign: "left",
      marginLeft: 30,
      marginRight: 20,
      marginVertical: 0,
    },
    section_L50: {
      textAlign: "left",
      marginLeft: 50,
      marginRight: 20,
      marginVertical: 0,
    },
    section_L150: {
      textAlign: "left",
      marginLeft: 150,
      marginRight: 20,
      marginVertical: 0,
    },
    section_4: {
      textAlign: "left",
      marginLeft: 20,
      marginVertical: 0,
    },
    section_5: {
      textAlign: "right",
      marginRight: 20,
      marginVertical: 0,
    },
    section_6: {
      position: "absolute",
      top: 20,
      right: 50,
    },
    boxContainer: {
      flexDirection: "row",
    },
  });

  return (
    <div>
      <PDFDownloadLink
        document={<MyDoc />}
        fileName={
          "防火設備[" +
          state.f0.f01_i1 +
          "-" +
          state.f0.f01_i2 +
          "]" +
          state.f0.f01_ro1 +
          "_報告書_結果表_概要書.pdf"
        }
        style={{
          color: "#ffffff",
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Button fullWidth variant="contained" style={{ background: "#a1887f" }}>
          申請書及び概要書PDFの出力
        </Button>
      </PDFDownloadLink>
    </div>
  );
};
export default PdfFireEquipment;
