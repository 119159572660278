import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  Svg,
  Line,
} from "@react-pdf/renderer";

import Button from "@mui/material/Button";

import {
  createTableHeaderA4,
  createTableRowA4,
  tableStyleA4,
} from "../Parts/TableA4";
import {
  createTableHeader_20_20_40_20,
  createTableHeader_head_A,
  createTableRow_5_95,
  createTableRow_5_30_45_5_5_5_5,
  createTableRow_5_25_50_5_5_5_5,
  createTableRow_5_8_27_40_5_5_5_5,
  createTableRow_5_8_22_45_5_5_5_5,
  createTableRow_5_8_17_50_5_5_5_5,
  createTableRow_5_8_67_5_5_5_5,
  createTableRow_100,
  createTableRow_100_A,
  createTableRow_100_regular,
  createTableRow_5_25_30_30_10,
  createTableRow_5_25_30_30_10_header,
  tableStyle_2,
  tableStyle_18,
  tableStyle_20,
} from "../Parts/CreateTable";

import fontRegular from "../fonts/Nasu-Regular.ttf"; //ttfファイル参照
import fontBold from "../fonts/Nasu-Bold.ttf"; //ttfファイル参照

const PdfArchitecture = forwardRef((props, ref) => {
  const state = useSelector((state) => state["Architecture"]["B1"]);

  const states = useSelector((state) => state["Architecture"]);

  const buldingNumberArchitecture = state["a0"]["a01_ro2"];

  Font.registerHyphenationCallback((word) =>
    word.length === 1 ? [word] : Array.from(word).map((char) => char)
  );

  const Page1 = ({ state, state0, state9 }) => {
    let pointList = [];

    for (let index = 1; index < buldingNumberArchitecture + 1; index++) {
      !!states[`B${index}`].a3.a32_1ro &&
        pointList.push(
          `【${index}棟】(敷地及び地盤)` + states[`B${index}`].a3.a32_1ro + "\n"
        );
      !!states[`B${index}`].a3.a32_2ro &&
        pointList.push(
          `【${index}棟】(建築物の外部)` + states[`B${index}`].a3.a32_2ro + "\n"
        );
      !!states[`B${index}`].a3.a32_3ro &&
        pointList.push(
          `【${index}棟】(屋上及び屋根)` + states[`B${index}`].a3.a32_3ro + "\n"
        );
      !!states[`B${index}`].a3.a32_4ro &&
        pointList.push(
          `【${index}棟】(建築物の内部)` + states[`B${index}`].a3.a32_4ro + "\n"
        );
      !!states[`B${index}`].a3.a32_5ro &&
        pointList.push(
          `【${index}棟】(避難施設)` + states[`B${index}`].a3.a32_5ro + "\n"
        );
      !!states[`B${index}`].a3.a32_6ro &&
        pointList.push(
          `【${index}棟】(その他)` + states[`B${index}`].a3.a32_6ro + "\n"
        );
    }

    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>
              第三十六号の二様式（第五条関係）（Ａ４）
            </Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>定期調査報告書</Text>
          </View>
          <View style={styles.section_6}>
            {!!state0.a01_i1 && (
              <Text style={styles.text1}>
                台帳番号 {state0.a01_i1}-{state0.a01_i2}
              </Text>
            )}
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>（第一面）</Text>
          </View>
          <View style={styles.section_3}>
            <Text style={styles.text1}>
              建築基準法第12条第１項の規定による定期調査の結果を報告します。
            </Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>
              この報告書に記載の事項は、事実に相違ありません。
            </Text>
          </View>
          <View style={styles.section_4}>
            {!!state9.a91_1 && (
              <Text style={styles.text1}>{state9.a91_1}様</Text>
            )}
          </View>
          <View style={styles.section_5}>
            {!!state0.a01_ho_1 && (
              <Text style={styles.text1}>
                {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                  era: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(state0.a01_ho_1))}
              </Text>
            )}
          </View>
          <View style={styles.section_5}>
            {!!state0.a01_ha && (
              <Text style={styles.text1}>報告者氏名 {state0.a01_ha}</Text>
            )}
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_5}>
            {!!state0.a01_ni && (
              <Text style={styles.text1}>調査者氏名 {state0.a01_ni}</Text>
            )}
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【1.所有者】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．氏名のフリガナ】
              </Text>
              {!!state.a11_i && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a11_i}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
              {!!state.a11_ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a11_ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．郵便番号】
              </Text>
              {!!state.a11_ha && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a11_ha}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
              {!!state.a11_ni && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a11_ni}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．電話番号】　
              </Text>
              {!!state.a11_ho && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a11_ho}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【2.管理者】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．氏名のフリガナ】　
              </Text>
              {!!state.a12_i && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a12_i}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
              {!!state.a12_ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a12_ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．郵便番号】
              </Text>
              {!!state.a12_ha && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a12_ha}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
              {!!state.a12_ni && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a12_ni}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．電話番号】
              </Text>
              {!!state.a12_ho && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a12_ho}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【3.調査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる調査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.a13_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a13_ai1}
                </Text>
              )}
              {!!state.a13_ai2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a13_ai2}
                </Text>
              )}
              {!!state.a13_ai3 && (
                <Text style={styles.text1}>{`第${state.a13_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>
                特定建築物調査員
              </Text>
              {!!state.a13_ai4 && (
                <Text style={styles.text1}>{`第${state.a13_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.a13_aro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_aro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.a13_aha && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_aha}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.a13_ani1 && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_ani1}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.a13_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a13_ani2}
                </Text>
              )}
              {!!state.a13_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a13_ani3}知事登録
                </Text>
              )}
              {!!state.a13_ani4 && (
                <Text style={styles.text1}>{`第${state.a13_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.a13_aho && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_aho}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.a13_ahe && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_ahe}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.a13_ato && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_ato}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の調査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.a13_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a13_bi1}
                </Text>
              )}
              {!!state.a13_bi2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a13_bi2}
                </Text>
              )}
              {!!state.a13_bi3 && (
                <Text style={styles.text1}>{`第${state.a13_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>
                特定建築物調査員
              </Text>
              {!!state.a13_bi4 && (
                <Text style={styles.text1}>{`第${state.a13_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.a13_bro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_bro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.a13_bha && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_bha}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.a13_bni1 && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_bni1}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.a13_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a13_bni2}
                </Text>
              )}
              {!!state.a13_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a13_bni3}知事登録
                </Text>
              )}
              {!!state.a13_bni4 && (
                <Text style={styles.text1}>{`第${state.a13_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.a13_bho && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_bho}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.a13_bhe && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_bhe}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.a13_bto && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a13_bto}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【4.報告対象建築物】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．所在地】</Text>
              {!!state.a14_i && <Text style={styles.text1}>{state.a14_i}</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．名称のフリガナ】
              </Text>
              {!!state.a14_ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a14_ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．名称】　</Text>
              {!!state.a14_ha && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a14_ha}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．用途】　</Text>
              {!!state.a14_ni && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a14_ni}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【5.調査による指摘の概要】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】
              </Text>
              {!!state.a15_i1 && (
                <Text style={styles.text1}>{state.a15_i1}</Text>
              )}
              {!!state.a15_i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】　
              </Text>
              <Text style={[styles.text1, { maxWidth: 415 }]}>
                {state.a15_ro}
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.a15_ha1 && (
                <Text style={styles.text1}>{state.a15_ha1}</Text>
              )}
              {!!state.a15_ha2_1 && (
                <Text style={styles.text1}>{`(${state.a15_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ニ．その他特記事項】
              </Text>
              {!!state.a15_ni && (
                <Text style={[styles.text1, { maxWidth: 415 }]}>
                  {state.a15_ni}
                </Text>
              )}
            </View>
          </View>
        </Page>
      </>
    );
  };
  const Page2 = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}>
              {`(第二面) 第${bNumber}棟 ${state9.a92_1}`}
            </Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>建築物及びその敷地に関する事項</Text>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【1.敷地の位置】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．防火地域等】
              </Text>
              {!!state.a21_i1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a21_i1}
                </Text>
              )}
              {!!state.a21_i2 && (
                <Text style={[styles.text1, { width: 295 }]}>
                  {state.a21_i2}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．用途地域】
              </Text>
              {!!state.a21_ro && (
                <Text style={[styles.text1, { width: 395 }]}>
                  {state.a21_ro}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【2.建築物及びその敷地の概要】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．構造】</Text>
              {!!state.a22_i1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a22_i1}
                </Text>
              )}
              {!!state.a22_i2 && (
                <Text style={[styles.text1, { width: 285 }]}>
                  {state.a22_i2}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ロ．階数】</Text>
              {!!state.a22_ro1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地上{state.a22_ro1}階
                </Text>
              )}
              {!!state.a22_ro2 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地下{state.a22_ro2}階
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．敷地面積】
              </Text>
              {!!state.a22_ha && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a22_ha}㎡
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ニ．建築面積】
              </Text>
              {!!state.a22_ni && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a22_ni}㎡
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．延べ面積】
              </Text>
              {!!state.a22_ho && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a22_ho}㎡
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 300 }]}>
              【3.階別用途別床面積】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>(用途)</Text>
              <Text style={[styles.text1, { width: 80 }]}>(床面積)</Text>
              <Text style={[styles.text1, { width: 175 }]}>コメント</Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}>
                【イ．階別用途別】
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i11 ? `(${state.a23_i11})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i121 ? `(${state.a23_i121})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i131 ? `(${state.a23_i131}㎡)` : "-"}
              </Text>
              {!!state.a23_i141 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i141}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i122 ? `(${state.a23_i122})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i132 ? `(${state.a23_i132}㎡)` : "-"}
              </Text>
              {!!state.a23_i142 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i142}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i123 ? `(${state.a23_i123})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i133 ? `(${state.a23_i133}㎡)` : "-"}
              </Text>
              {!!state.a23_i143 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i143}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i21 ? `(${state.a23_i21})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i221 ? `(${state.a23_i221})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i231 ? `(${state.a23_i231}㎡)` : "-"}
              </Text>
              {!!state.a23_i241 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i241}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i222 ? `(${state.a23_i222})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i232 ? `(${state.a23_i232}㎡)` : "-"}
              </Text>
              {!!state.a23_i242 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i242}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i223 ? `(${state.a23_i223})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i233 ? `(${state.a23_i233}㎡)` : "-"}
              </Text>
              {!!state.a23_i243 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i243}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i31 ? `(${state.a23_i31})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i321 ? `(${state.a23_i321})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i331 ? `(${state.a23_i331}㎡)` : "-"}
              </Text>
              {!!state.a23_i341 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i341}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i322 ? `(${state.a23_i322})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i332 ? `(${state.a23_i332}㎡)` : "-"}
              </Text>
              {!!state.a23_i342 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i342}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i323 ? `(${state.a23_i323})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i333 ? `(${state.a23_i333}㎡)` : "-"}
              </Text>
              {!!state.a23_i343 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i343}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i41 ? `(${state.a23_i41})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i421 ? `(${state.a23_i421})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i431 ? `(${state.a23_i431}㎡)` : "-"}
              </Text>
              {!!state.a23_i441 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i441}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i422 ? `(${state.a23_i422})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i432 ? `(${state.a23_i432}㎡)` : "-"}
              </Text>
              {!!state.a23_i442 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i442}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i423 ? `(${state.a23_i423})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i433 ? `(${state.a23_i433}㎡)` : "-"}
              </Text>
              {!!state.a23_i443 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i443}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i51 ? `(${state.a23_i51})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i521 ? `(${state.a23_i521})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i531 ? `(${state.a23_i531}㎡)` : "-"}
              </Text>
              {!!state.a23_i541 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i541}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i522 ? `(${state.a23_i522})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i532 ? `(${state.a23_i532}㎡)` : "-"}
              </Text>
              {!!state.a23_i542 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i542}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i523 ? `(${state.a23_i523})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i533 ? `(${state.a23_i533}㎡)` : "-"}
              </Text>
              {!!state.a23_i543 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i543}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}>
                【ロ．用途別】　　　
              </Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_ro121 ? `(${state.a23_ro121})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_ro131 ? `(${state.a23_ro131}㎡)` : "-"}
              </Text>
              {!!state.a23_ro141 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_ro141}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_ro122 ? `(${state.a23_ro122})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_ro132 ? `(${state.a23_ro132}㎡)` : "-"}
              </Text>
              {!!state.a23_ro142 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_ro142}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_ro123 ? `(${state.a23_ro123})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_ro133 ? `(${state.a23_ro133}㎡)` : "-"}
              </Text>
              {!!state.a23_ro143 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_ro143}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>
              【4.性能検証法等の適用】
            </Text>
          </View>
          <View style={styles.section_L150}>
            {!!state.a24_1 && <Text style={styles.text1}>耐火性能検証法</Text>}
          </View>
          <View style={styles.section_L150}>
            {!!state.a24_2 && <Text style={styles.text1}>防火区画検証法</Text>}
          </View>
          <View style={styles.section_L150}>
            <View style={styles.boxContainer}>
              {!!state.a24_31 && (
                <Text style={styles.text1}>区画避難安全検証法</Text>
              )}
              {!!state.a24_32 && (
                <Text style={styles.text1}>{`(${state.a24_32}階)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_L150}>
            <View style={styles.boxContainer}>
              {!!state.a24_41 && (
                <Text style={styles.text1}>階避難安全検証法</Text>
              )}
              {!!state.a24_42 && (
                <Text style={styles.text1}>{`(${state.a24_42}階)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_L150}>
            {!!state.a24_5 && (
              <Text style={styles.text1}>全館避難安全検証法</Text>
            )}
          </View>
          <View style={styles.section_L150}>
            <View style={styles.boxContainer}>
              {!!state.a24_61 && (
                <Text style={[styles.text1, { width: 80 }]}>その他</Text>
              )}
              {!!state.a24_62 && (
                <Text style={[styles.text1, { width: 355 }]}>
                  ({state.a24_62})
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【5.増築、改築、用途変更等の経過】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_11_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_11_1}
                </Text>
              )}
              {!!state.a25_12 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_12})
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_21_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_21_1}
                </Text>
              )}
              {!!state.a25_22 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_22})
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_31_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_31_1}
                </Text>
              )}
              {!!state.a25_32 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_32})
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_41_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_41_1}
                </Text>
              )}
              {!!state.a25_42 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_42})
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【6.関連図書の整備状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．確認に要した図書】
              </Text>
              {!!state.a26_i1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_i1}
                </Text>
              )}
              {!!state.a26_i2 && (
                <Text style={styles.text1}>(各階平面図あり)</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．確認済証】
              </Text>
              {!!state.a26_ro1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ro1}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.a26_ro2_1 && (
                <Text style={styles.text1}>
                  {`交付番号　`}
                  {`${new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.a26_ro2_1))}　`}
                </Text>
              )}
              {!!state.a26_ro3 && (
                <Text style={styles.text1}>{`第${state.a26_ro3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              <Text style={[styles.text1, { width: 50 }]}>交付者</Text>
              {!!state.a26_ro4 && (
                <Text style={styles.text1}>{state.a26_ro4}</Text>
              )}
              {!!state.a26_ro5 && (
                <Text style={styles.text1}>({state.a26_ro5})</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ハ．完了検査に要した図書】
              </Text>
              {!!state.a26_ha && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ha}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ニ．検査済証】
              </Text>
              {!!state.a26_ni1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ni1}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.a26_ni2_1 && (
                <Text style={styles.text1}>
                  {`交付番号　`}
                  {`${new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.a26_ni2_1))}　`}
                </Text>
              )}
              {!!state.a26_ni3 && (
                <Text style={styles.text1}>{`第${state.a26_ni3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              <Text style={[styles.text1, { width: 50 }]}>交付者</Text>
              {!!state.a26_ni4 && (
                <Text style={styles.text1}>{state.a26_ni4}</Text>
              )}
              {!!state.a26_ni5 && (
                <Text style={styles.text1}>({state.a26_ni5})</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ホ．維持保全に関する準則又は計画】
              </Text>
              {!!state.a26_ho && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ho}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ヘ．前回の調査に関する書類の写し】
              </Text>
              {!!state.a26_he && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_he}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>【7.備考】</Text>
          </View>
          {!!state.a27_1 && (
            <View style={styles.section_3}>
              <Text style={[styles.text1, { width: 545 }]}>{state.a27_1}</Text>
            </View>
          )}
        </Page>
      </>
    );
  };

  const Page3 = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}>
              {`(第三面) 第${bNumber}棟 ${state9.a92_1}`}
            </Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>調査等の概要</Text>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【1.調査及び検査の状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 180 }]}>
                【イ．今回の調査】　
              </Text>
              {!!state.a31_i_1 && (
                <Text style={styles.text1}>{state.a31_i_1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．前回の調査】
              </Text>
              {!!state.a31_ro1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a31_ro1}
                </Text>
              )}
              {!!state.a31_ro2_1 && (
                <Text style={styles.text1}>{state.a31_ro2_1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．建築設備の検査】
              </Text>
              {!!state.a31_ha1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a31_ha1}
                </Text>
              )}
              {!!state.a31_ha2_1 && (
                <Text style={styles.text1}>{state.a31_ha2_1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ニ．昇降機等の検査】
              </Text>
              {!!state.a31_ni1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a31_ni1}
                </Text>
              )}
              {!!state.a31_ni2_1 && (
                <Text style={styles.text1}>{state.a31_ni2_1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．防火設備の検査】
              </Text>
              {!!state.a31_ho1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a31_ho1}
                </Text>
              )}
              {!!state.a31_ho2_1 && (
                <Text style={styles.text1}>{state.a31_ho2_1}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【2.調査の状況】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（敷地及び地盤）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】　
              </Text>
              {!!state.a32_1i1 && (
                <Text style={styles.text1}>{state.a32_1i1}</Text>
              )}
              {!!state.a32_1i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】
              </Text>
              {!!state.a32_1ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a32_1ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.a32_1ha1 && (
                <Text style={styles.text1}>{state.a32_1ha1}</Text>
              )}
              {!!state.a32_1ha2_1 && (
                <Text style={styles.text1}>{`(${state.a32_1ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（建築物の外部）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】　
              </Text>
              {!!state.a32_2i1 && (
                <Text style={styles.text1}>{state.a32_2i1}</Text>
              )}
              {!!state.a32_2i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】
              </Text>
              {!!state.a32_2ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a32_2ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.a32_2ha1 && (
                <Text style={styles.text1}>{state.a32_2ha1}</Text>
              )}
              {!!state.a32_2ha2_1 && (
                <Text style={styles.text1}>{`(${state.a32_2ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（屋上及び屋根）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】　
              </Text>
              {!!state.a32_3i1 && (
                <Text style={styles.text1}>{state.a32_3i1}</Text>
              )}
              {!!state.a32_3i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】
              </Text>
              {!!state.a32_3ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a32_3ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.a32_3ha1 && (
                <Text style={styles.text1}>{state.a32_3ha1}</Text>
              )}
              {!!state.a32_3ha2_1 && (
                <Text style={styles.text1}>{`(${state.a32_3ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（建築物の内部）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】　
              </Text>
              {!!state.a32_4i1 && (
                <Text style={styles.text1}>{state.a32_4i1}</Text>
              )}
              {!!state.a32_4i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】
              </Text>
              {!!state.a32_4ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a32_4ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.a32_4ha1 && (
                <Text style={styles.text1}>{state.a32_4ha1}</Text>
              )}
              {!!state.a32_4ha2_1 && (
                <Text style={styles.text1}>{`(${state.a32_4ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（避難施設等）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】　
              </Text>
              {!!state.a32_5i1 && (
                <Text style={styles.text1}>{state.a32_5i1}</Text>
              )}
              {!!state.a32_5i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】
              </Text>
              {!!state.a32_5ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a32_5ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.a32_5ha1 && (
                <Text style={styles.text1}>{state.a32_5ha1}</Text>
              )}
              {!!state.a32_5ha2_1 && (
                <Text style={styles.text1}>{`(${state.a32_5ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】　
              </Text>
              {!!state.a32_6i1 && (
                <Text style={styles.text1}>{state.a32_6i1}</Text>
              )}
              {!!state.a32_6i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】
              </Text>
              {!!state.a32_6ro && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a32_6ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.a32_6ha1 && (
                <Text style={styles.text1}>{state.a32_6ha1}</Text>
              )}
              {!!state.a32_6ha2_1 && (
                <Text style={styles.text1}>{`(${state.a32_6ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>
              【3.石綿を添加した建築材料の調査状況】　
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．該当建築材料の有無】
              </Text>
              {!!state.a33_i1 && (
                <Text style={[styles.text1, { width: 150 }]}>
                  有(飛散防止措置無)
                </Text>
              )}
              {!!state.a33_i2 && (
                <Text style={[styles.text1, { width: 245 }]}>
                  {state.a33_i2}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.a33_i3 && (
                <Text style={[styles.text1, { width: 150 }]}>
                  有(飛散防止措置有)
                </Text>
              )}
              {!!state.a33_i4 && (
                <Text style={[styles.text1, { width: 245 }]}>
                  {state.a33_i4}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.a33_i5 && (
                <Text style={[styles.text1, { width: 150 }]}>無</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．措置予定の有無】
              </Text>
              {!!state.a33_ro1 && (
                <Text style={styles.text1}>{state.a33_ro1}</Text>
              )}
              {!!state.a33_ro2_1 && (
                <Text style={styles.text1}>{`(${state.a33_ro2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>
              【4.耐震診断及び耐震改修の調査状況】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．耐震診断の実施の有無】
              </Text>
              {!!state.a34_i1 && (
                <Text style={styles.text1}>{state.a34_i1}</Text>
              )}
              {!!state.a34_i2_1 && (
                <Text style={styles.text1}>{`(${state.a34_i2_1.slice(
                  0,
                  -2
                )}月に実施予定)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．耐震改修の実施の有無】
              </Text>
              {!!state.a34_ro1 && (
                <Text style={styles.text1}>{state.a34_ro1}</Text>
              )}
              {!!state.a34_ro2_1 && (
                <Text style={styles.text1}>{`(${state.a34_ro2_1.slice(
                  0,
                  -2
                )}月に実施予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【5.建築物等に係る不具合等の状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．不具合等】
              </Text>
              {!!state.a35_i && <Text style={styles.text1}>{state.a35_i}</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．不具合等の記録】
              </Text>
              {!!state.a35_ro && (
                <Text style={styles.text1}>{state.a35_ro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善の状況】　　
              </Text>
              {!!state.a35_ha1 && (
                <Text style={styles.text1}>{state.a35_ha1}</Text>
              )}
              {!!state.a35_ha2_1 && (
                <Text style={styles.text1}>{`(${state.a35_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【6.備考】</Text>
          </View>
          <View style={styles.section_3}>
            {!!state.a36_1 && (
              <Text style={[styles.text1, { width: 545 }]}>{state.a36_1}</Text>
            )}
          </View>
        </Page>
      </>
    );
  };

  const Page4 = ({ state }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}>(第四面)</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>建築物等に係る不具合等の状況</Text>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={tableStyleA4}>
            {createTableHeaderA4()}
            {createTableRowA4(
              state.a4_11_1,
              state.a4_12,
              state.a4_13,
              state.a4_14_1,
              state.a4_15
            )}
            {createTableRowA4(
              state.a4_21_1,
              state.a4_22,
              state.a4_23,
              state.a4_24_1,
              state.a4_25
            )}
            {createTableRowA4(
              state.a4_31_1,
              state.a4_32,
              state.a4_33,
              state.a4_34_1,
              state.a4_35
            )}
            {createTableRowA4(
              state.a4_41_1,
              state.a4_42,
              state.a4_43,
              state.a4_44_1,
              state.a4_45
            )}
            {createTableRowA4(
              state.a4_51_1,
              state.a4_52,
              state.a4_53,
              state.a4_54_1,
              state.a4_55
            )}
          </View>
        </Page>
      </>
    );
  };

  const PageInvest = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記（A４)　</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>
              {`調査結果表 第${bNumber}棟 ${state9.a92_1}`}
            </Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_20_20_40_20(
              state.a50_11,
              state.a50_12,
              state.a50_21,
              state.a50_22,
              state.a50_31,
              state.a50_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_A()}
            {createTableRow_5_95("1", "敷地及び地盤")}
            {createTableRow_5_25_50_5_5_5_5(
              "(1)",
              "地盤",
              "地盤沈下等による不陸、傾斜等の状況",
              state.a51_1_11,
              state.a51_1_12,
              state.a51_1_13
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(2)",
              "敷地",
              "敷地内の排水の状況",
              state.a51_1_21,
              state.a51_1_22,
              state.a51_1_23
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(3)",
              "敷地内の通路",
              "敷地内の通路の確保の状況",
              state.a51_1_31,
              state.a51_1_32,
              state.a51_1_33,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(4)",
              "",
              "有効幅員の確保の状況",
              state.a51_1_41,
              state.a51_1_42,
              state.a51_1_43,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(5)",
              "",
              "敷地内の通路の支障物の状況",
              state.a51_1_51,
              state.a51_1_52,
              state.a51_1_53
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(6)",
              "塀",
              `組積造の塀又は補強コンクリートブロック造の塀等の耐震対策の状況`,
              state.a51_1_61,
              state.a51_1_62,
              state.a51_1_63,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(7)",
              "",
              `組積造の塀又は補強コンクリートブロック造の塀等の劣化及び損傷の状況`,
              state.a51_1_71,
              state.a51_1_72,
              state.a51_1_73
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(8)",
              "擁壁",
              `擁壁の劣化及び損傷の状況`,
              state.a51_1_81,
              state.a51_1_82,
              state.a51_1_83,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(9)",
              "",
              `擁壁の水抜きパイプの維持保全の状況`,
              state.a51_1_91,
              state.a51_1_92,
              state.a51_1_93
            )}
            {createTableRow_5_95("2", "建築物の外部")}
            {createTableRow_5_25_50_5_5_5_5(
              "(1)",
              "基礎",
              `基礎の沈下等の状況`,
              state.a51_2_11,
              state.a51_2_12,
              state.a51_2_13,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(2)",
              "",
              `基礎の劣化及び損傷の状況`,
              state.a51_2_21,
              state.a51_2_22,
              state.a51_2_23
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(3)",
              "土台（木造に限る。）",
              `土台の沈下等の状況`,
              state.a51_2_31,
              state.a51_2_32,
              state.a51_2_33,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(4)",
              "",
              `土台の劣化及び損傷の状況`,
              state.a51_2_41,
              state.a51_2_42,
              state.a51_2_43
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(5)",
              "外壁",
              "躯体等",
              `外壁、軒裏及び外壁の開口部で延焼のおそれのある部分の防火対策の状況`,
              state.a51_2_51,
              state.a51_2_52,
              state.a51_2_53,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(6)",
              "",
              "",
              `木造の外壁躯体の劣化及び損傷の状況`,
              state.a51_2_61,
              state.a51_2_62,
              state.a51_2_63,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(7)",
              "",
              "",
              `組積造の外壁躯体の劣化及び損傷の状況`,
              state.a51_2_71,
              state.a51_2_72,
              state.a51_2_73,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(8)",
              "",
              "",
              `補強コンクリートブロック造の外壁躯体の劣化及び損傷の状況`,
              state.a51_2_81,
              state.a51_2_82,
              state.a51_2_83,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(9)",
              "",
              "",
              `鉄骨造の外壁躯体の劣化及び損傷の状況`,
              state.a51_2_91,
              state.a51_2_92,
              state.a51_2_93,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(10)",
              "",
              "",
              `鉄筋コンクリート造及び鉄骨鉄筋コンクリート造の
          外壁躯体の劣化及び損傷の状況`,
              state.a51_2_101,
              state.a51_2_102,
              state.a51_2_103,
              0,
              1
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(11)",
              "",
              "外装仕上げ材等",
              `タイル、石貼り等（乾式工法によるものを除く。）、
          モルタル等の劣化及び損傷の状況`,
              state.a51_2_111,
              state.a51_2_112,
              state.a51_2_113,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(12)",
              "",
              "",
              `乾式工法によるタイル、石貼り等の劣化及び損傷の状況`,
              state.a51_2_121,
              state.a51_2_122,
              state.a51_2_123,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(13)",
              "",
              "",
              `金属系パネル（帳壁を含む。）の劣化及び損傷の状況`,
              state.a51_2_131,
              state.a51_2_132,
              state.a51_2_133,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(14)",
              "",
              "",
              `コンクリート系パネル（帳壁を含む。）の劣化及び損傷の状況`,
              state.a51_2_141,
              state.a51_2_142,
              state.a51_2_143,
              0,
              1
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(15)",
              "",
              "窓サッシ等",
              `サッシ等の劣化及び損傷の状況`,
              state.a51_2_151,
              state.a51_2_152,
              state.a51_2_153,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(16)",
              "",
              "",
              `はめ殺し窓のガラスの固定の状況`,
              state.a51_2_161,
              state.a51_2_162,
              state.a51_2_163,
              0,
              1
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(17)",
              "",
              "外壁に緊結された",
              `機器本体の劣化及び損傷の状況`,
              state.a51_2_171,
              state.a51_2_172,
              state.a51_2_173,
              0,
              0
            )}
            {createTableRow_5_8_17_50_5_5_5_5(
              "(18)",
              "",
              "広告板、空調室外機等",
              `支持部分等の劣化及び損傷の状況`,
              state.a51_2_181,
              state.a51_2_182,
              state.a51_2_183
            )}
            {createTableRow_5_95("3", "屋上及び屋根")}
            {createTableRow_5_25_50_5_5_5_5(
              "(1)",
              "屋上面",
              "屋上面の劣化及び損傷の状況",
              state.a51_3_11,
              state.a51_3_12,
              state.a51_3_13
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(2)",
              "屋上周り（屋上面を除く。）",
              `パラペットの立上り面の劣化及び損傷の状況`,
              state.a51_3_21,
              state.a51_3_22,
              state.a51_3_23,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(3)",
              "",
              `笠木モルタル等の劣化及び損傷の状況`,
              state.a51_3_31,
              state.a51_3_32,
              state.a51_3_33,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(4)",
              "",
              `金属笠木の劣化及び損傷の状況`,
              state.a51_3_41,
              state.a51_3_42,
              state.a51_3_43,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(5)",
              "",
              `排水溝（ドレーンを含む。）の劣化及び損傷の状況`,
              state.a51_3_51,
              state.a51_3_52,
              state.a51_3_53
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(6)",
              "屋根",
              `屋根の防火対策の状況`,
              state.a51_3_61,
              state.a51_3_62,
              state.a51_3_63,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(7)",
              "",
              `屋根の劣化及び損傷の状況`,
              state.a51_3_71,
              state.a51_3_72,
              state.a51_3_73
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(8)",
              "機器及び工作物",
              `機器、工作物本体及び接合部の劣化及び損傷の状況`,
              state.a51_3_81,
              state.a51_3_82,
              state.a51_3_83,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(9)",
              "（冷却塔設備、広告塔等）",
              `支持部分等の劣化及び損傷の状況`,
              state.a51_3_91,
              state.a51_3_92,
              state.a51_3_93
            )}
            {createTableRow_5_95("4", "建築物の内部")}
            {createTableRow_5_8_67_5_5_5_5(
              "(1)",
              "防火区画",
              `令第112条第11項から第13項までに規定する区画の状況`,
              state.a51_4_11,
              state.a51_4_12,
              state.a51_4_13,
              0
            )}
            {createTableRow_5_8_67_5_5_5_5(
              "(2)",
              "",
              `令第112条第１項、第４項、第５項又は第７項から第10項までの各項に規定する区画の状況`,
              state.a51_4_21,
              state.a51_4_22,
              state.a51_4_23,
              0
            )}
            {createTableRow_5_8_67_5_5_5_5(
              "(3)",
              "",
              `令第112条第18項に規定する区画の状況`,
              state.a51_4_31,
              state.a51_4_32,
              state.a51_4_33,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(4)",
              "",
              "防火区画の外周部",
              `令第112条第16項に規定する外壁等及び同条第17項に規定する
          防火設備の処置の状況`,
              state.a51_4_41,
              state.a51_4_42,
              state.a51_4_43,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(5)",
              "",
              "",
              `令第112条第16項に規定する外壁等及び同条第17項に規定する
          防火設備の劣化及び損傷の状況`,
              state.a51_4_51,
              state.a51_4_52,
              state.a51_4_53
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(6)",
              `壁の室内に`,
              "躯体等",
              `木造の壁の室内に面する部分の躯体の劣化及び損傷の状況`,
              state.a51_4_61,
              state.a51_4_62,
              state.a51_4_63,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(7)",
              "面する部分",
              "",
              `組積造の壁の室内に面する部分の躯体の劣化及び損傷の状況`,
              state.a51_4_71,
              state.a51_4_72,
              state.a51_4_73,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(8)",
              "",
              "",
              `補強コンクリートブロック造の壁の室内に面する部分の
          躯体の劣化及び損傷の状況`,
              state.a51_4_81,
              state.a51_4_82,
              state.a51_4_83,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(9)",
              "",
              "",
              `鉄骨造の壁の室内に面する部分の躯体の劣化及び損傷の状況`,
              state.a51_4_91,
              state.a51_4_92,
              state.a51_4_93,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(10)",
              "",
              "",
              `鉄筋コンクリート造及び鉄骨鉄筋コンクリート造の壁の
          室内に面する部分の躯体の劣化及び損傷の状況`,
              state.a51_4_101,
              state.a51_4_102,
              state.a51_4_103,
              0,
              1
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(11)",
              "",
              `耐火構造の壁又は準耐火構造の壁`,
              `準耐火性能等の確保の状況`,
              state.a51_4_111,
              state.a51_4_112,
              state.a51_4_113,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(12)",
              "",
              `（防火区画を構成する壁等に限る。）`,
              `部材の劣化及び損傷の状況`,
              state.a51_4_121,
              state.a51_4_122,
              state.a51_4_123,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(13)",
              "",
              ``,
              `鉄骨の耐火被覆の劣化及び損傷の状況`,
              state.a51_4_131,
              state.a51_4_132,
              state.a51_4_133,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(14)",
              "",
              ``,
              `給水管、配電管その他の管又は風道の区画貫通部の
          充填等の処理の状況`,
              state.a51_4_141,
              state.a51_4_142,
              state.a51_4_143,
              0,
              1
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(15)",
              "",
              `令第114条に規定する界壁、
          間仕切壁及び隔壁`,
              `令第114条に規定する界壁、間仕切壁及び隔壁の状況`,
              state.a51_4_151,
              state.a51_4_152,
              state.a51_4_153,
              0,
              1
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(16)",
              "",
              `令第128条の5各項に規定する
          建築物の壁の室内に面する部分`,
              `室内に面する部分の仕上げの維持保全の状況`,
              state.a51_4_161,
              state.a51_4_162,
              state.a51_4_163
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={tableStyle_20}>
            {createTableHeader_head_A()}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(17)",
              "床",
              `躯体等`,
              `木造の床躯体の劣化及び損傷の状況`,
              state.a51_4_171,
              state.a51_4_172,
              state.a51_4_173,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(18)",
              "",
              ``,
              `鉄骨造の床躯体の劣化及び損傷の状況`,
              state.a51_4_181,
              state.a51_4_182,
              state.a51_4_183,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(19)",
              "",
              ``,
              `鉄筋コンクリート造及び鉄骨鉄筋コンクリート造の
          床躯体の劣化及び損傷の状況`,
              state.a51_4_191,
              state.a51_4_192,
              state.a51_4_193,
              0,
              1
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(20)",
              "",
              `耐火構造の床又は準耐火構造の床`,
              `準耐火性能等の確保の状況`,
              state.a51_4_201,
              state.a51_4_202,
              state.a51_4_203,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(21)",
              "",
              `（防火区画を構成する床に限る。）`,
              `部材の劣化及び損傷の状況`,
              state.a51_4_211,
              state.a51_4_212,
              state.a51_4_213,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(22)",
              "",
              ``,
              `給水管、配電管その他の管又は風道の区画貫通部の
          充填等の処理の状況`,
              state.a51_4_221,
              state.a51_4_222,
              state.a51_4_223,
              1,
              1
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(23)",
              "天井",
              `令第128条の5各項に規定する建築物の`,
              `室内に面する部分の仕上げの維持保全の状況`,
              state.a51_4_231,
              state.a51_4_232,
              state.a51_4_233,
              0,
              0
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(24)",
              "",
              `天井の室内に面する部分`,
              `室内に面する部分の仕上げの劣化及び損傷の状況`,
              state.a51_4_241,
              state.a51_4_242,
              state.a51_4_243,
              0,
              1
            )}
            {createTableRow_5_8_27_40_5_5_5_5(
              "(25)",
              "",
              `特定天井`,
              `特定天井の天井材の劣化及び損傷の状況`,
              state.a51_4_251,
              state.a51_4_252,
              state.a51_4_253,
              1,
              1
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(26)",
              `防火設備（防火扉、`,
              `区画に対応した防火設備又は戸の設置の状況`,
              state.a51_4_261,
              state.a51_4_262,
              state.a51_4_263,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(27)",
              `防火シャッターその他
          これらに類するものに`,
              `居室から地上へ通じる主たる廊下、階段その他の通路に設置
          された防火設備又は戸におけるくぐり戸の設置の状況`,
              state.a51_4_271,
              state.a51_4_272,
              state.a51_4_273,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(28)",
              `限る。）又は戸`,
              `昭和48年建設省告示第2563号第１第１号ロに規定する基準への適合の状況`,
              state.a51_4_281,
              state.a51_4_282,
              state.a51_4_283,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(29)",
              ``,
              `防火扉又は戸の開放方向`,
              state.a51_4_291,
              state.a51_4_292,
              state.a51_4_293,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(30)",
              ``,
              `常閉防火設備等の本体と枠の劣化及び損傷の状況`,
              state.a51_4_301,
              state.a51_4_302,
              state.a51_4_303,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(31)",
              ``,
              `常閉防火設備等の閉鎖又は作動の状況`,
              state.a51_4_311,
              state.a51_4_312,
              state.a51_4_313,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(32)",
              ``,
              `常閉防火設備等の閉鎖又は作動の障害となる物品の放置の状況`,
              state.a51_4_321,
              state.a51_4_322,
              state.a51_4_323,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(33)",
              ``,
              `常閉防火扉等の固定の状況`,
              state.a51_4_331,
              state.a51_4_332,
              state.a51_4_333
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(34)",
              `照明器具、懸垂物等`,
              `照明器具、懸垂物等の落下防止対策の状況`,
              state.a51_4_341,
              state.a51_4_342,
              state.a51_4_343,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(35)",
              ``,
              `防火設備又は戸の閉鎖の障害となる照明器具、懸垂物等の状況`,
              state.a51_4_351,
              state.a51_4_352,
              state.a51_4_353
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(36)",
              `警報設備`,
              `警報設備の設置の状況`,
              state.a51_4_361,
              state.a51_4_362,
              state.a51_4_363,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(37)",
              ``,
              `警報設備の劣化及び損傷の状況`,
              state.a51_4_371,
              state.a51_4_372,
              state.a51_4_373
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(38)",
              `居室の採光及び換気`,
              `採光のための開口部の面積の確保の状況`,
              state.a51_4_381,
              state.a51_4_382,
              state.a51_4_383,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(39)",
              ``,
              `採光の妨げとなる物品の放置の状況`,
              state.a51_4_391,
              state.a51_4_392,
              state.a51_4_393,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(40)",
              ``,
              `換気のための開口部の面積の確保の状況`,
              state.a51_4_401,
              state.a51_4_402,
              state.a51_4_403,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(41)",
              ``,
              `換気設備の設置の状況`,
              state.a51_4_411,
              state.a51_4_412,
              state.a51_4_413,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(42)",
              ``,
              `換気設備の作動の状況`,
              state.a51_4_421,
              state.a51_4_422,
              state.a51_4_423,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(43)",
              ``,
              `換気の妨げとなる物品の放置の状況`,
              state.a51_4_431,
              state.a51_4_432,
              state.a51_4_433
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(44)",
              `石綿等を添加した建築材料`,
              `吹付け石綿及び吹付けロックウールでその含有する石綿の重量が
          当該建築材料の重量の0.1パーセントを超えるもの(以下「吹付け
            石綿等」という)の使用の状況`,
              state.a51_4_441,
              state.a51_4_442,
              state.a51_4_443,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(45)",
              ``,
              `吹付け石綿等の劣化の状況`,
              state.a51_4_451,
              state.a51_4_452,
              state.a51_4_453,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(46)",
              ``,
              `除去又は囲い込み若しくは封じ込めによる飛散防止措置の実施の状況`,
              state.a51_4_461,
              state.a51_4_462,
              state.a51_4_463,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(47)",
              ``,
              `囲い込み又は封じ込めによる飛散防止措置の劣化及び損傷の状況`,
              state.a51_4_471,
              state.a51_4_472,
              state.a51_4_473
            )}
            {createTableRow_5_95("5", "避難施設等")}
            {createTableRow_5_30_45_5_5_5_5(
              "(1)",
              `令第120条第２項に規定する通路`,
              `令第120条第２項に規定する通路の確保の状況`,
              state.a51_5_11,
              state.a51_5_12,
              state.a51_5_13
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(2)",
              `廊下`,
              `幅員の確保の状況`,
              state.a51_5_21,
              state.a51_5_22,
              state.a51_5_23,
              0
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(3)",
              ``,
              `物品の放置の状況`,
              state.a51_5_31,
              state.a51_5_32,
              state.a51_5_33
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(4)",
              `出入口`,
              `出入口の確保の状況`,
              state.a51_5_41,
              state.a51_5_42,
              state.a51_5_43,
              0
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(5)",
              ``,
              `物品の放置の状況`,
              state.a51_5_51,
              state.a51_5_52,
              state.a51_5_53
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(6)",
              `屋上広場`,
              `屋上広場の確保の状況`,
              state.a51_5_61,
              state.a51_5_62,
              state.a51_5_63
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(7)",
              `避難上有効なバルコニー`,
              `避難上有効なバルコニーの確保の状況`,
              state.a51_5_71,
              state.a51_5_72,
              state.a51_5_73,
              0
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(8)",
              ``,
              `手すり等の劣化及び損傷の状況`,
              state.a51_5_81,
              state.a51_5_82,
              state.a51_5_83,
              0
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(9)",
              ``,
              `物品の放置の状況`,
              state.a51_5_91,
              state.a51_5_92,
              state.a51_5_93,
              0
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(10)",
              ``,
              `避難器具の操作性の確保の状況`,
              state.a51_5_101,
              state.a51_5_102,
              state.a51_5_103
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(11)",
              "階段",
              "階段",
              `直通階段の設置の状況`,
              state.a51_5_111,
              state.a51_5_112,
              state.a51_5_113,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(12)",
              "",
              "",
              `幅員の確保の状況`,
              state.a51_5_121,
              state.a51_5_122,
              state.a51_5_123,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(13)",
              "",
              "",
              `手すりの設置の状況`,
              state.a51_5_131,
              state.a51_5_132,
              state.a51_5_133,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(14)",
              "",
              "",
              `物品の放置の状況`,
              state.a51_5_141,
              state.a51_5_142,
              state.a51_5_143,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(15)",
              "",
              "",
              `階段各部の劣化及び損傷の状況`,
              state.a51_5_151,
              state.a51_5_152,
              state.a51_5_153,
              0,
              1
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(16)",
              "",
              "屋内に設けられた避難階段",
              `階段室の構造の状況`,
              state.a51_5_161,
              state.a51_5_162,
              state.a51_5_163,
              0,
              1
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(17)",
              "",
              "屋外に設けられた避難階段",
              `屋内と階段との間の防火区画の確保の状況`,
              state.a51_5_171,
              state.a51_5_172,
              state.a51_5_173,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(18)",
              "",
              "",
              `開放性の確保の状況`,
              state.a51_5_181,
              state.a51_5_182,
              state.a51_5_183,
              0,
              1
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(19)",
              "",
              "特別避難階段",
              `バルコニー又は付室の構造及び面積の確保の状況`,
              state.a51_5_191,
              state.a51_5_192,
              state.a51_5_193,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(20)",
              "",
              "",
              `付室等の排煙設備の設置の状況`,
              state.a51_5_201,
              state.a51_5_202,
              state.a51_5_203,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(21)",
              "",
              "",
              `付室等の排煙設備の作動の状況`,
              state.a51_5_211,
              state.a51_5_212,
              state.a51_5_213,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(22)",
              "",
              "",
              `付室等の外気に向かって開くことができる窓の状況`,
              state.a51_5_221,
              state.a51_5_222,
              state.a51_5_223,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(23)",
              "",
              "",
              `物品の放置の状況`,
              state.a51_5_231,
              state.a51_5_232,
              state.a51_5_233
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(24)",
              "排煙設備等",
              "防煙壁",
              `防煙区画の設置の状況`,
              state.a51_5_241,
              state.a51_5_242,
              state.a51_5_243,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(25)",
              "",
              "",
              `防煙壁の劣化及び損傷の状況`,
              state.a51_5_251,
              state.a51_5_252,
              state.a51_5_253,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(26)",
              "",
              "",
              `可動式防煙壁の作動の状況`,
              state.a51_5_261,
              state.a51_5_262,
              state.a51_5_263
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={tableStyle_20}>
            {createTableHeader_head_A()}

            {createTableRow_5_8_22_45_5_5_5_5(
              "(27)",
              "",
              "排煙設備",
              `排煙設備の設置の状況`,
              state.a51_5_271,
              state.a51_5_272,
              state.a51_5_273,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(28)",
              "",
              "",
              `排煙設備の作動の状況`,
              state.a51_5_281,
              state.a51_5_282,
              state.a51_5_283,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(29)",
              "",
              "",
              `排煙口の維持保全の状況`,
              state.a51_5_291,
              state.a51_5_292,
              state.a51_5_293
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(30)",
              `その他の`,
              `非常用の進入口等`,
              `非常用の進入口等の設置の状況`,
              state.a51_5_301,
              state.a51_5_302,
              state.a51_5_303,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(31)",
              `設備等`,
              ``,
              `非常用の進入口等の維持保全の状況`,
              state.a51_5_311,
              state.a51_5_312,
              state.a51_5_313,
              0,
              1
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(32)",
              ``,
              `非常用エレベーター`,
              `乗降ロビーの構造及び面積の確保の状況`,
              state.a51_5_321,
              state.a51_5_322,
              state.a51_5_323,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(33)",
              ``,
              ``,
              `乗降ロビー等の排煙設備の設置の状況`,
              state.a51_5_331,
              state.a51_5_332,
              state.a51_5_333,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(34)",
              ``,
              ``,
              `乗降ロビー等の排煙設備の作動の状況`,
              state.a51_5_341,
              state.a51_5_342,
              state.a51_5_343,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(35)",
              ``,
              ``,
              `乗降ロビーの付室の外気に向かって開くことができる窓の状況`,
              state.a51_5_351,
              state.a51_5_352,
              state.a51_5_353,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(36)",
              ``,
              ``,
              `物品の放置の状況`,
              state.a51_5_361,
              state.a51_5_362,
              state.a51_5_363,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(37)",
              ``,
              ``,
              `非常用エレベーターの作動の状況`,
              state.a51_5_371,
              state.a51_5_372,
              state.a51_5_373,
              0,
              1
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(38)",
              ``,
              `非常用の照明装置`,
              `非常用の照明装置の設置の状況`,
              state.a51_5_381,
              state.a51_5_382,
              state.a51_5_383,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(39)",
              ``,
              ``,
              `非常用の照明装置の作動の状況`,
              state.a51_5_391,
              state.a51_5_392,
              state.a51_5_393,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(40)",
              ``,
              ``,
              `照明の妨げとなる物品の放置の状況`,
              state.a51_5_401,
              state.a51_5_402,
              state.a51_5_403
            )}
            {createTableRow_5_95("6", "その他")}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(1)",
              `特殊な`,
              `膜構造建築物の膜体、取付部材等`,
              `膜体及び取付部材の劣化及び損傷の状況`,
              state.a51_6_11,
              state.a51_6_12,
              state.a51_6_13,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(2)",
              `構造等`,
              ``,
              `膜張力及びケーブル張力の状況`,
              state.a51_6_21,
              state.a51_6_22,
              state.a51_6_23,
              0,
              1
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(3)",
              ``,
              `免震構造建築物の免震層
          及び免震装置`,
              `免震装置の劣化及び損傷の状況
          （免震装置が可視状態にある場合に限る。）`,
              state.a51_6_31,
              state.a51_6_32,
              state.a51_6_33,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(4)",
              ``,
              ``,
              `上部構造の可動の状況`,
              state.a51_6_41,
              state.a51_6_42,
              state.a51_6_43
            )}
            {createTableRow_5_30_45_5_5_5_5(
              "(5)",
              `避雷設備`,
              `避雷針、避雷導線等の劣化及び損傷の状況`,
              state.a51_6_51,
              state.a51_6_52,
              state.a51_6_53
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(6)",
              `煙突`,
              `建築物に設ける煙突`,
              `煙突本体及び建築物との接合部の劣化及び損傷の状況`,
              state.a51_6_61,
              state.a51_6_62,
              state.a51_6_63,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(7)",
              ``,
              ``,
              `付帯金物の劣化及び損傷の状況`,
              state.a51_6_71,
              state.a51_6_72,
              state.a51_6_73,
              0,
              1
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(8)",
              ``,
              `令第138条第１項第１号`,
              `煙突本体の劣化及び損傷の状況`,
              state.a51_6_81,
              state.a51_6_82,
              state.a51_6_83,
              0,
              0
            )}
            {createTableRow_5_8_22_45_5_5_5_5(
              "(9)",
              ``,
              `に掲げる煙突`,
              `付帯金物の劣化及び損傷の状況`,
              state.a51_6_91,
              state.a51_6_92,
              state.a51_6_93
            )}
            {createTableRow_100_A("その他確認事項")}
            {createTableRow_100_regular(
              "法第12条第3項の規定による検査を要する防火設備の有無"
            )}
            {state.a51_8_11 === "有"
              ? createTableRow_100_A(
                  `${state.a51_8_11} 　（${state.a51_8_12}階）`
                )
              : createTableRow_100_A(`${state.a51_8_11} `)}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "調査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等"
            )}
            {createTableRow_5_25_30_30_10(
              state.a51_9_11,
              state.a51_9_12,
              state.a51_9_13,
              state.a51_9_14,
              state.a51_9_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.a51_9_21,
              state.a51_9_22,
              state.a51_9_23,
              state.a51_9_24,
              state.a51_9_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.a51_9_31,
              state.a51_9_32,
              state.a51_9_33,
              state.a51_9_34,
              state.a51_9_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.a51_9_41,
              state.a51_9_42,
              state.a51_9_43,
              state.a51_9_44,
              state.a51_9_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.a51_9_51,
              state.a51_9_52,
              state.a51_9_53,
              state.a51_9_54,
              state.a51_9_55_1
            )}
          </View>
        </Page>
      </>
    );
  };

  const PageSummry11 = ({ state }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1}>
          <Text style={styles.text1}>
            第三十六号の三様式（第五条、第六条の三、第十一条の三関係）（Ａ４）
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>定期調査報告概要書</Text>
        </View>
        <View style={styles.section_6}>
          {!!state.a01_i_1 && (
            <Text style={styles.text1}>
              台帳番号 {state.a01_i_1}-{state.a01_i_2}
            </Text>
          )}
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>(第一面)</Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>調査等の概要</Text>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【1.所有者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】
            </Text>
            {!!state.a11_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a11_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.a11_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a11_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.a11_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a11_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.a11_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a11_ni}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【2.管理者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】　
            </Text>
            {!!state.a12_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a12_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.a12_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a12_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.a12_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a12_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.a12_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a12_ni}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【3.調査者】</Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>（代表となる調査者）</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
            {!!state.a13_ai1 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.a13_ai1}
              </Text>
            )}
            {!!state.a13_ai2 && (
              <Text style={[styles.text1, { width: 50 }]}>{state.a13_ai2}</Text>
            )}
            {!!state.a13_ai3 && (
              <Text style={styles.text1}>{`第${state.a13_ai3}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            <Text style={[styles.text1, { width: 180 }]}>特定建築物調査員</Text>
            {!!state.a13_ai4 && (
              <Text style={styles.text1}>{`第${state.a13_ai4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．氏名のフリガナ】
            </Text>
            {!!state.a13_aro && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_aro}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
            {!!state.a13_aha && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_aha}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
            {!!state.a13_ani1 && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_ani1}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            {!!state.a13_ani2 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.a13_ani2}
              </Text>
            )}
            {!!state.a13_ani3 && (
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a13_ani3}知事登録
              </Text>
            )}
            {!!state.a13_ani4 && (
              <Text style={styles.text1}>{`第${state.a13_ani4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．郵便番号】</Text>
            {!!state.a13_aho && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_aho}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ヘ．所在地】　</Text>
            {!!state.a13_ahe && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_ahe}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ト．電話番号】　
            </Text>
            {!!state.a13_ato && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_ato}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>（その他の調査者）</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
            {!!state.a13_bi1 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.a13_bi1}
              </Text>
            )}
            {!!state.a13_bi2 && (
              <Text style={[styles.text1, { width: 50 }]}>{state.a13_bi2}</Text>
            )}
            {!!state.a13_bi3 && (
              <Text style={styles.text1}>{`第${state.a13_bi3}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            <Text style={[styles.text1, { width: 180 }]}>特定建築物調査員</Text>
            {!!state.a13_bi4 && (
              <Text style={styles.text1}>{`第${state.a13_bi4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．氏名のフリガナ】
            </Text>
            {!!state.a13_bro && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_bro}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
            {!!state.a13_bha && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_bha}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
            {!!state.a13_bni1 && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_bni1}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}></Text>
            {!!state.a13_bni2 && (
              <Text style={[styles.text1, { width: 130 }]}>
                {state.a13_bni2}
              </Text>
            )}
            {!!state.a13_bni3 && (
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a13_bni3}知事登録
              </Text>
            )}
            {!!state.a13_bni4 && (
              <Text style={styles.text1}>{`第${state.a13_bni4}号`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．郵便番号】</Text>
            {!!state.a13_bho && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_bho}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ヘ．所在地】　</Text>
            {!!state.a13_bhe && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_bhe}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ト．電話番号】　
            </Text>
            {!!state.a13_bto && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state.a13_bto}
              </Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【4.報告対象建築物】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．所在地】</Text>
            {!!state.a14_i && <Text style={styles.text1}>{state.a14_i}</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．名称のフリガナ】
            </Text>
            {!!state.a14_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a14_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．名称】　</Text>
            {!!state.a14_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a14_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．用途】　</Text>
            {!!state.a14_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.a14_ni}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【5.調査による指摘の概要】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．指摘の内容】
            </Text>
            {!!state.a15_i1 && <Text style={styles.text1}>{state.a15_i1}</Text>}
            {!!state.a15_i2 && <Text style={styles.text1}>（既存不適格）</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．指摘の概要】　
            </Text>
            <Text style={[styles.text1, { maxWidth: 415 }]}>
              {state.a15_ro}
            </Text>
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．改善予定の有無】
            </Text>
            {!!state.a15_ha1 && (
              <Text style={styles.text1}>{state.a15_ha1}</Text>
            )}
            {!!state.a15_ha2_1 && (
              <Text style={styles.text1}>{`(${state.a15_ha2_1.slice(
                0,
                -2
              )}月に改善予定)`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ニ．その他特記事項】
            </Text>
            {!!state.a15_ni && (
              <Text style={[styles.text1, { maxWidth: 415 }]}>
                {state.a15_ni}
              </Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
      </Page>
    );
  };

  const PageSummry12 = ({ state, bNumber, state9 }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1_1}>
          <Text style={styles.text1}>
            {`(第一面) 第${bNumber}棟 ${state9.a92_1}`}
          </Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【6.調査及び検査の状況】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 180 }]}>
              【イ．今回の調査】　
            </Text>
            {!!state.a31_i_1 && (
              <Text style={styles.text1}>{state.a31_i_1}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．前回の調査】
            </Text>
            {!!state.a31_ro1 && (
              <Text style={[styles.text1, { width: 50 }]}>{state.a31_ro1}</Text>
            )}
            {!!state.a31_ro2_1 && (
              <Text style={styles.text1}>{state.a31_ro2_1}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．建築設備の検査】
            </Text>
            {!!state.a31_ha1 && (
              <Text style={[styles.text1, { width: 50 }]}>{state.a31_ha1}</Text>
            )}
            {!!state.a31_ha2_1 && (
              <Text style={styles.text1}>{state.a31_ha2_1}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ニ．昇降機等の検査】
            </Text>
            {!!state.a31_ni1 && (
              <Text style={[styles.text1, { width: 50 }]}>{state.a31_ni1}</Text>
            )}
            {!!state.a31_ni2_1 && (
              <Text style={styles.text1}>{state.a31_ni2_1}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ホ．防火設備の検査】
            </Text>
            {!!state.a31_ho1 && (
              <Text style={[styles.text1, { width: 50 }]}>{state.a31_ho1}</Text>
            )}
            {!!state.a31_ho2_1 && (
              <Text style={styles.text1}>{state.a31_ho2_1}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【7.建築物等に係る不具合等の状況】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．不具合等】</Text>
            {!!state.a35_i && <Text style={styles.text1}>{state.a35_i}</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．不具合等の記録】
            </Text>
            {!!state.a35_ro && <Text style={styles.text1}>{state.a35_ro}</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．改善の状況】　　
            </Text>
            {!!state.a35_ha1 && (
              <Text style={styles.text1}>{state.a35_ha1}</Text>
            )}
            {!!state.a35_ha2_1 && (
              <Text style={styles.text1}>{`(${state.a35_ha2_1.slice(
                0,
                -2
              )}月に改善予定)`}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
      </Page>
    );
  };

  const PageSummry2 = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}>
              {`(第二面) 第${bNumber}棟 ${state9.a92_1}`}
            </Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>建築物及びその敷地に関する事項</Text>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【1.敷地の位置】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．防火地域等】
              </Text>
              {!!state.a21_i1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a21_i1}
                </Text>
              )}
              {!!state.a21_i2 && (
                <Text style={[styles.text1, { width: 295 }]}>
                  {state.a21_i2}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．用途地域】
              </Text>
              {!!state.a21_ro && (
                <Text style={[styles.text1, { width: 395 }]}>
                  {state.a21_ro}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【2.建築物及びその敷地の概要】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．構造】</Text>
              {!!state.a22_i1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.a22_i1}
                </Text>
              )}
              {!!state.a22_i2 && (
                <Text style={[styles.text1, { width: 285 }]}>
                  {state.a22_i2}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ロ．階数】</Text>
              {!!state.a22_ro1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地上{state.a22_ro1}階
                </Text>
              )}
              {!!state.a22_ro2 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地下{state.a22_ro2}階
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．敷地面積】
              </Text>
              {!!state.a22_ha && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a22_ha}㎡
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ニ．建築面積】
              </Text>
              {!!state.a22_ni && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a22_ni}㎡
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．延べ面積】
              </Text>
              {!!state.a22_ho && (
                <Text style={[styles.text1, { width: 415 }]}>
                  {state.a22_ho}㎡
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 300 }]}>
              【3.階別用途別床面積】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>(用途)</Text>
              <Text style={[styles.text1, { width: 80 }]}>(床面積)</Text>
              <Text style={[styles.text1, { width: 175 }]}>コメント</Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}>
                【イ．階別用途別】
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i11 ? `(${state.a23_i11})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i121 ? `(${state.a23_i121})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i131 ? `(${state.a23_i131}㎡)` : "-"}
              </Text>
              {!!state.a23_i141 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i141}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i122 ? `(${state.a23_i122})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i132 ? `(${state.a23_i132}㎡)` : "-"}
              </Text>
              {!!state.a23_i142 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i142}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i123 ? `(${state.a23_i123})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i133 ? `(${state.a23_i133}㎡)` : "-"}
              </Text>
              {!!state.a23_i143 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i143}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i21 ? `(${state.a23_i21})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i221 ? `(${state.a23_i221})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i231 ? `(${state.a23_i231}㎡)` : "-"}
              </Text>
              {!!state.a23_i241 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i241}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i222 ? `(${state.a23_i222})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i232 ? `(${state.a23_i232}㎡)` : "-"}
              </Text>
              {!!state.a23_i242 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i242}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i223 ? `(${state.a23_i223})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i233 ? `(${state.a23_i233}㎡)` : "-"}
              </Text>
              {!!state.a23_i243 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i243}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i31 ? `(${state.a23_i31})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i321 ? `(${state.a23_i321})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i331 ? `(${state.a23_i331}㎡)` : "-"}
              </Text>
              {!!state.a23_i341 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i341}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i322 ? `(${state.a23_i322})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i332 ? `(${state.a23_i332}㎡)` : "-"}
              </Text>
              {!!state.a23_i342 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i342}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i323 ? `(${state.a23_i323})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i333 ? `(${state.a23_i333}㎡)` : "-"}
              </Text>
              {!!state.a23_i343 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i343}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i41 ? `(${state.a23_i41})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i421 ? `(${state.a23_i421})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i431 ? `(${state.a23_i431}㎡)` : "-"}
              </Text>
              {!!state.a23_i441 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i441}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i422 ? `(${state.a23_i422})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i432 ? `(${state.a23_i432}㎡)` : "-"}
              </Text>
              {!!state.a23_i442 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i442}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i423 ? `(${state.a23_i423})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i433 ? `(${state.a23_i433}㎡)` : "-"}
              </Text>
              {!!state.a23_i443 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i443}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i51 ? `(${state.a23_i51})階` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i521 ? `(${state.a23_i521})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i531 ? `(${state.a23_i531}㎡)` : "-"}
              </Text>
              {!!state.a23_i541 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i541}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i522 ? `(${state.a23_i522})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i532 ? `(${state.a23_i532}㎡)` : "-"}
              </Text>
              {!!state.a23_i542 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i542}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_i523 ? `(${state.a23_i523})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_i533 ? `(${state.a23_i533}㎡)` : "-"}
              </Text>
              {!!state.a23_i543 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_i543}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}>
                【ロ．用途別】　　　
              </Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_ro121 ? `(${state.a23_ro121})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_ro131 ? `(${state.a23_ro131}㎡)` : "-"}
              </Text>
              {!!state.a23_ro141 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_ro141}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_ro122 ? `(${state.a23_ro122})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_ro132 ? `(${state.a23_ro132}㎡)` : "-"}
              </Text>
              {!!state.a23_ro142 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_ro142}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 110 }]}></Text>
              <Text style={[styles.text1, { width: 80 }]}></Text>
              <Text style={[styles.text1, { width: 100 }]}>
                {state.a23_ro123 ? `(${state.a23_ro123})` : "-"}
              </Text>
              <Text style={[styles.text1, { width: 80 }]}>
                {state.a23_ro133 ? `(${state.a23_ro133}㎡)` : "-"}
              </Text>
              {!!state.a23_ro143 && (
                <Text style={[styles.text1, { width: 175 }]}>
                  {state.a23_ro143}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>
              【4.性能検証法等の適用】
            </Text>
          </View>
          <View style={styles.section_L150}>
            {!!state.a24_1 && <Text style={styles.text1}>耐火性能検証法</Text>}
          </View>
          <View style={styles.section_L150}>
            {!!state.a24_2 && <Text style={styles.text1}>防火区画検証法</Text>}
          </View>
          <View style={styles.section_L150}>
            <View style={styles.boxContainer}>
              {!!state.a24_31 && (
                <Text style={styles.text1}>区画避難安全検証法</Text>
              )}
              {!!state.a24_32 && (
                <Text style={styles.text1}>{`(${state.a24_32}階)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_L150}>
            <View style={styles.boxContainer}>
              {!!state.a24_41 && (
                <Text style={styles.text1}>階避難安全検証法</Text>
              )}
              {!!state.a24_42 && (
                <Text style={styles.text1}>{`(${state.a24_42}階)`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_L150}>
            {!!state.a24_5 && (
              <Text style={styles.text1}>全館避難安全検証法</Text>
            )}
          </View>
          <View style={styles.section_L150}>
            <View style={styles.boxContainer}>
              {!!state.a24_61 && (
                <Text style={[styles.text1, { width: 80 }]}>その他</Text>
              )}
              {!!state.a24_62 && (
                <Text style={[styles.text1, { width: 355 }]}>
                  ({state.a24_62})
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【5.増築、改築、用途変更等の経過】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_11_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_11_1}
                </Text>
              )}
              {!!state.a25_12 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_12})
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_21_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_21_1}
                </Text>
              )}
              {!!state.a25_22 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_22})
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_31_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_31_1}
                </Text>
              )}
              {!!state.a25_32 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_32})
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.a25_41_1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.a25_41_1}
                </Text>
              )}
              {!!state.a25_42 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  概要 ({state.a25_42})
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【6.関連図書の整備状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．確認に要した図書】
              </Text>
              {!!state.a26_i1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_i1}
                </Text>
              )}
              {!!state.a26_i2 && (
                <Text style={styles.text1}>(各階平面図あり)</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．確認済証】
              </Text>
              {!!state.a26_ro1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ro1}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.a26_ro2_1 && (
                <Text style={styles.text1}>
                  {`交付番号　`}
                  {`${new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.a26_ro2_1))}　`}
                </Text>
              )}
              {!!state.a26_ro3 && (
                <Text style={styles.text1}>{`第${state.a26_ro3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              <Text style={[styles.text1, { width: 50 }]}>交付者</Text>
              {!!state.a26_ro4 && (
                <Text style={styles.text1}>{state.a26_ro4}</Text>
              )}
              {!!state.a26_ro5 && (
                <Text style={styles.text1}>({state.a26_ro5})</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ハ．完了検査に要した図書】
              </Text>
              {!!state.a26_ha && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ha}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ニ．検査済証】
              </Text>
              {!!state.a26_ni1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ni1}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.a26_ni2_1 && (
                <Text style={styles.text1}>
                  {`交付番号　`}
                  {`${new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.a26_ni2_1))}　`}
                </Text>
              )}
              {!!state.a26_ni3 && (
                <Text style={styles.text1}>{`第${state.a26_ni3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              <Text style={[styles.text1, { width: 50 }]}>交付者</Text>
              {!!state.a26_ni4 && (
                <Text style={styles.text1}>{state.a26_ni4}</Text>
              )}
              {!!state.a26_ni5 && (
                <Text style={styles.text1}>({state.a26_ni5})</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ホ．維持保全に関する準則又は計画】
              </Text>
              {!!state.a26_ho && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_ho}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ヘ．前回の調査に関する書類の写し】
              </Text>
              {!!state.a26_he && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.a26_he}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>【7.備考】</Text>
          </View>
          {!!state.a27_1 && (
            <View style={styles.section_3}>
              <Text style={[styles.text1, { width: 545 }]}>{state.a27_1}</Text>
            </View>
          )}
        </Page>
      </>
    );
  };

  const MyDoc = () => {
    let annexList = [];
    let summryList = [];

    if (buldingNumberArchitecture > 1) {
      for (let index = 2; index < buldingNumberArchitecture + 1; index++) {
        annexList.push(
          <Page2
            key={`${index}-page2`}
            state={states[`B${index}`]["a2"]}
            bNumber={index}
            state9={states[`B${index}`]["a9"]}
          />
        );
        annexList.push(
          <Page3
            key={`${index}-page3`}
            state={states[`B${index}`]["a3"]}
            bNumber={index}
            state9={states[`B${index}`]["a9"]}
          />
        );
        annexList.push(
          <PageInvest
            key={`${index}-pageInvest`}
            state={states[`B${index}`]["a5"]}
            bNumber={index}
            state9={states[`B${index}`]["a9"]}
          />
        );
        summryList.push(
          <PageSummry12
            key={`${index}-Summry12`}
            state={states[`B${index}`]["a3"]}
            bNumber={index}
            state9={states[`B${index}`]["a9"]}
          />
        );
        summryList.push(
          <PageSummry2
            key={`${index}-summary2`}
            state={states[`B${index}`]["a2"]}
            bNumber={index}
            state9={states[`B${index}`]["a9"]}
          />
        );
      }
    }

    let pointList = [];

    for (let index = 1; index < buldingNumberArchitecture + 1; index++) {
      !!states[`B${index}`].a3.a32_1ro &&
        pointList.push(
          `【${index}棟】(敷地及び地盤)` + states[`B${index}`].a3.a32_1ro + "\n"
        );
      !!states[`B${index}`].a3.a32_2ro &&
        pointList.push(
          `【${index}棟】(建築物の外部)` + states[`B${index}`].a3.a32_2ro + "\n"
        );
      !!states[`B${index}`].a3.a32_3ro &&
        pointList.push(
          `【${index}棟】(屋上及び屋根)` + states[`B${index}`].a3.a32_3ro + "\n"
        );
      !!states[`B${index}`].a3.a32_4ro &&
        pointList.push(
          `【${index}棟】(建築物の内部)` + states[`B${index}`].a3.a32_4ro + "\n"
        );
      !!states[`B${index}`].a3.a32_5ro &&
        pointList.push(
          `【${index}棟】(避難施設)` + states[`B${index}`].a3.a32_5ro + "\n"
        );
      !!states[`B${index}`].a3.a32_6ro &&
        pointList.push(
          `【${index}棟】(その他)` + states[`B${index}`].a3.a32_6ro + "\n"
        );
    }

    return (
      <Document>
        <Page1 state={state.a1} state0={state.a0} state9={state.a9} />
        <Page2 state={state.a2} bNumber={1} state9={state.a9} />
        <Page3 state={state.a3} bNumber={1} state9={state.a9} />
        <Page4 state={state.a4} />
        <PageInvest state={state.a5} bNumber={1} state9={state.a9} />
        {annexList}
        <PageSummry11 state={state.a1} />
        <PageSummry12 state={state.a3} bNumber={1} state9={state.a9} />
        <PageSummry2 state={state.a2} bNumber={1} state9={state.a9} />
        {summryList}
      </Document>
    );
  };

  Font.register({
    family: "Nasu-Regular",
    src: fontRegular,
  });

  Font.register({
    family: "Nasu-Bold",
    src: fontBold,
  });

  const styles = StyleSheet.create({
    text1: {
      fontSize: "10.5pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 1,
    },
    textBold: {
      fontSize: "10.5pt",
      fontFamily: "Nasu-Bold",
      lineHeight: 1,
    },
    textTable: {
      textAlign: "center",
      fontSize: "10.5pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 2,
    },

    section_1: {
      textAlign: "left",
      marginTop: 18,
      marginLeft: 20,
      marginRight: 20,
    },
    section_1_1: {
      textAlign: "center",
      marginTop: 18,
      marginLeft: 20,
      marginRight: 20,
    },
    section_2: {
      textAlign: "center",
      marginHorizontal: 20,
      marginVertical: 0,
    },
    section_3: {
      textAlign: "left",
      marginLeft: 30,
      marginRight: 20,
      marginVertical: 0,
    },
    section_L50: {
      textAlign: "left",
      marginLeft: 50,
      marginRight: 20,
      marginVertical: 0,
    },
    section_L150: {
      textAlign: "left",
      marginLeft: 150,
      marginRight: 20,
      marginVertical: 0,
    },
    section_4: {
      textAlign: "left",
      marginLeft: 20,
      marginVertical: 0,
    },
    section_5: {
      textAlign: "right",
      marginRight: 20,
      marginLeft: 20,
      marginVertical: 0,
    },
    section_6: {
      position: "absolute",
      top: 20,
      right: 50,
    },
    boxContainer: {
      flexDirection: "row",
    },
  });

  return (
    <div>
      <PDFDownloadLink
        document={<MyDoc />}
        fileName={
          "建築物[" +
          state.a0.a01_i1 +
          "-" +
          state.a0.a01_i2 +
          "]" +
          state.a0.a01_ro1 +
          "_報告書_結果表_概要書.pdf"
        }
        style={{
          color: "#ffffff",
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          style={{ background: "#a1887f" }}
          ref={ref}
        >
          申請書及び概要書PDFの出力
        </Button>
      </PDFDownloadLink>
    </div>
  );
});
export default React.memo(PdfArchitecture);
