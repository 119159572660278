import React from "react";

import { Text, View, Font, StyleSheet } from "@react-pdf/renderer";

import fontRegular from "../fonts/Nasu-Regular.ttf"; //ttfファイル参照
import fontBold from "../fonts/Nasu-Bold.ttf"; //ttfファイル参照

Font.register({
  family: "Nasu-Regular",
  src: fontRegular,
});

// フォント「ナス 太字」
Font.register({
  family: "Nasu-Bold",
  src: fontBold,
});

// CSSスタイル定義
const styles = StyleSheet.create({
  textTableCenter: {
    textAlign: "center",
    fontSize: "8pt",
    fontFamily: "Nasu-Regular",
    lineHeight: 1.2,
  },
  textTableCenterBold: {
    textAlign: "center",
    fontSize: "8pt",
    fontFamily: "Nasu-Bold",
    lineHeight: 1.2,
  },
  textTableLeft: {
    textAlign: "left",
    fontSize: "8pt",
    fontFamily: "Nasu-Regular",
    lineHeight: 1.2,
  },
  textTableLeftBold: {
    textAlign: "left",
    fontSize: "8pt",
    fontFamily: "Nasu-Bold",
    lineHeight: 1.2,
  },
  textTable_15: {
    textAlign: "center",
    fontSize: "8pt",
    fontFamily: "Nasu-Regular",
    lineHeight: 1.5,
  },
});

export const createTableHeader_20_20_40_20 = (
  state1,
  state2,
  state3,
  state4,
  state5,
  state6
) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc_P("20%", 1, 1, 1, 1)}>
        <Text style={styles.textTable_15}>
          当該調査
          {"\n"}
          に関与した
          {"\n"}
          調査者
        </Text>
      </View>

      <View style={TableColStyleFunc_P("20%", 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}> </Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>代表となる調査者</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}>その他の調査者</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}> </Text>
          </View>
        </View>
      </View>

      <View style={TableColStyleFunc_P("40%", 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>氏名</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}> {state1 ? state1 : "-"}</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>{state3 ? state3 : "-"} </Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}> {state5 ? state5 : "-"}</Text>
          </View>
        </View>
      </View>

      <View style={TableColStyleFunc_P("20%", 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>調査者番号</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}> {state2 ? state2 : "-"}</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>{state4 ? state4 : "-"} </Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}> {state6 ? state6 : "-"}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export const createTableHeader_E_F_20_20_40_20 = (
  state1,
  state2,
  state3,
  state4,
  state5,
  state6
) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc_P("20%", 1, 1, 1, 1)}>
        <Text style={styles.textTable_15}>
          当該検査
          {"\n"}
          に関与した
          {"\n"}
          検査者
        </Text>
      </View>

      <View style={TableColStyleFunc_P("20%", 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}> </Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>代表となる検査者</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}>その他の検査者</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}> </Text>
          </View>
        </View>
      </View>

      <View style={TableColStyleFunc_P("40%", 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>氏名</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}> {state1 ? state1 : "-"}</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>{state3 ? state3 : "-"} </Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}> {state5 ? state5 : "-"}</Text>
          </View>
        </View>
      </View>

      <View style={TableColStyleFunc_P("20%", 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>検査者番号</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}> {state2 ? state2 : "-"}</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTable_15}>{state4 ? state4 : "-"} </Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <Text style={styles.textTable_15}> {state6 ? state6 : "-"}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export const createTableHeader_head_A = () => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(5, 1, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>番号</Text>
      </View>

      <View style={TableColStyleFunc(75, 0, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>調査項目</Text>
      </View>

      <View style={TableColStyleFunc(15, 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTableCenter}>調査結果</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <View style={tableRowStyle} fixed>
              <View style={TableColStyleFunc_P("33%", 0, 1, 0, 0)}>
                <Text style={styles.textTableCenter}>
                  指摘
                  {"\n"}
                  なし
                </Text>
              </View>

              <View style={TableColStyleFunc_P("33%", 0, 1, 0, 0)}>
                <Text style={styles.textTableCenter}>要是正</Text>
              </View>

              <View style={TableColStyleFunc_P("34%", 0, 0, 0, 0)}>
                <Text style={styles.textTableCenter}>
                  既存
                  {"\n"}
                  不適格
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={TableColStyleFunc(5, 0, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>
          担当
          {"\n"}
          調査者
          {"\n"}
          番号
        </Text>
      </View>
    </View>
  );
};

export const createTableHeader_head_E = () => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(5, 1, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>番号</Text>
      </View>

      <View style={TableColStyleFunc(75, 0, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>検査項目等</Text>
      </View>

      <View style={TableColStyleFunc(15, 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTableCenter}>検査結果</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <View style={tableRowStyle} fixed>
              <View style={TableColStyleFunc_P("33%", 0, 1, 0, 0)}>
                <Text style={styles.textTableCenter}>
                  指摘
                  {"\n"}
                  なし
                </Text>
              </View>

              <View style={TableColStyleFunc_P("33%", 0, 1, 0, 0)}>
                <Text style={styles.textTableCenter}>要是正</Text>
              </View>

              <View style={TableColStyleFunc_P("34%", 0, 0, 0, 0)}>
                <Text style={styles.textTableCenter}>
                  既存
                  {"\n"}
                  不適格
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={TableColStyleFunc(5, 0, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>
          担当
          {"\n"}
          検査者
          {"\n"}
          番号
        </Text>
      </View>
    </View>
  );
};

export const createTableHeader_head_F = () => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(5, 1, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>番号</Text>
      </View>

      <View style={TableColStyleFunc(34, 0, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>検査項目</Text>
      </View>

      <View style={TableColStyleFunc(41, 0, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>検査事項</Text>
      </View>

      <View style={TableColStyleFunc(15, 0, 1, 1, 1)}>
        <View style={tableColumStyle} fixed>
          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 1)}>
            <Text style={styles.textTableCenter}>検査結果</Text>
          </View>

          <View style={TableColStyleFunc_P("100%", 0, 0, 0, 0)}>
            <View style={tableRowStyle} fixed>
              <View style={TableColStyleFunc_P("33%", 0, 1, 0, 0)}>
                <Text style={styles.textTableCenter}>
                  指摘
                  {"\n"}
                  なし
                </Text>
              </View>

              <View style={TableColStyleFunc_P("33%", 0, 1, 0, 0)}>
                <Text style={styles.textTableCenter}>要是正</Text>
              </View>

              <View style={TableColStyleFunc_P("34%", 0, 0, 0, 0)}>
                <Text style={styles.textTableCenter}>
                  既存
                  {"\n"}
                  不適格
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={TableColStyleFunc(5, 0, 1, 1, 1)}>
        <Text style={styles.textTableCenter}>
          担当
          {"\n"}
          検査者
          {"\n"}
          番号
        </Text>
      </View>
    </View>
  );
};

export const createTableRow_5_95 = (num, title) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
        {!!num && <Text style={styles.textTableCenterBold}>{num}</Text>}
      </View>

      <View style={TableColStyleFunc(95, 0, 1, 0, 1)}>
        {!!title && <Text style={styles.textTableLeftBold}>{title}</Text>}
      </View>
    </View>
  );
};

export const createTableRow_5_30_45_5_5_5_5 = (
  num,
  title1,
  title2,
  result1,
  result2,
  inve,
  bottom1 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(30, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(45, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(30, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(45, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_8_17_50_5_5_5_5 = (
  num,
  title1,
  title2,
  title3,
  result1,
  result2,
  inve,
  bottom1 = 1,
  bottom2 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(17, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(50, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(17, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(50, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_8_22_45_5_5_5_5 = (
  num,
  title1,
  title2,
  title3,
  result1,
  result2,
  inve,
  bottom1 = 1,
  bottom2 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(22, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(45, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(22, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(45, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_8_27_40_5_5_5_5 = (
  num,
  title1,
  title2,
  title3,
  result1,
  result2,
  inve,
  bottom1 = 1,
  bottom2 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(27, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(40, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(27, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(40, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_12_22_41_5_5_5_5 = (
  num,
  title1,
  title2,
  title3,
  result1,
  result2,
  inve,
  bottom1 = 1,
  bottom2 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(22, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(41, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(22, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(41, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_12_22_41_5_5_5_5_color = (
  num,
  title1,
  title2,
  title3,
  result1,
  result2,
  inve,
  bottom1 = 1,
  bottom2 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View
        style={[tableRowStyle, { backgroundColor: "rgba(159,158,173,0.3)" }]}
        fixed
      >
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(22, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(41, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View
        style={[tableRowStyle, { backgroundColor: "rgba(159,158,173,0.3)" }]}
        fixed
      >
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(22, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(41, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_34_41_5_5_5_5 = (
  num,
  title1,
  title2,
  result1,
  result2,
  inve,
  bottom1 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(34, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(41, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(34, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(41, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_12_63_5_5_5_5 = (
  num,
  title1,
  title2,
  result1,
  result2,
  inve,
  bottom1 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(63, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(63, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_25_50_5_5_5_5 = (
  num,
  title1,
  title2,
  result1,
  result2,
  inve,
  bottom1 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(25, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(50, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(25, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(50, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_40_35_5_5_5_5 = (
  num,
  title1,
  title2,
  result1,
  result2,
  inve,
  bottom1 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(40, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(35, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(40, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(35, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_8_67_5_5_5_5 = (
  num,
  title1,
  title2,
  result1,
  result2,
  inve,
  bottom1 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(67, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(8, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(67, 0, 1, 0, 1)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_12_18_45_5_5_5_5 = (
  num,
  title1,
  title2,
  title3,
  result1,
  result2,
  inve,
  bottom1 = 1,
  bottom2 = 1
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          {!!num && <Text style={styles.textTableCenter}>{num}</Text>}
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          {!!title1 && <Text style={styles.textTableLeft}>{title1}</Text>}
        </View>

        <View style={TableColStyleFunc(18, 0, 1, 0, bottom2)}>
          {!!title2 && <Text style={styles.textTableLeft}>{title2}</Text>}
        </View>

        <View style={TableColStyleFunc(45, 0, 1, 0, 1)}>
          {!!title3 && <Text style={styles.textTableLeft}>{title3}</Text>}
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{num}</Text>
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, bottom1)}>
          <Text style={styles.textTableLeft}>{title1}</Text>
        </View>

        <View style={TableColStyleFunc(18, 0, 1, 0, bottom2)}>
          <Text style={styles.textTableLeft}>{title2}</Text>
        </View>

        <View style={TableColStyleFunc(45, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title3}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_12_28_35_5_5_5_5 = (
  num,
  title1,
  title2,
  title3,
  result1,
  result2,
  inve
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{num}</Text>
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title1}</Text>
        </View>

        <View style={TableColStyleFunc(28, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title2}</Text>
        </View>

        <View style={TableColStyleFunc(35, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title3}</Text>
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{num}</Text>
        </View>

        <View style={TableColStyleFunc(12, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title1}</Text>
        </View>

        <View style={TableColStyleFunc(28, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title2}</Text>
        </View>

        <View style={TableColStyleFunc(35, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title3}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_5_75_5_5_5_5 = (
  num,
  title1,
  result1,
  result2,
  inve
) => {
  if (result1 === "対象外") {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{num}</Text>
        </View>

        <View style={TableColStyleFunc(75, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title1}</Text>
        </View>

        <View style={TableColStyleFunc(15, 0, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{"対象外"}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  } else {
    return (
      <View style={tableRowStyle} fixed>
        <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
          <Text style={styles.textTableCenter}>{num}</Text>
        </View>

        <View style={TableColStyleFunc(75, 0, 1, 0, 1)}>
          <Text style={styles.textTableLeft}>{title1}</Text>
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "指摘なし" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result1 && (
            <Text style={styles.textTableCenter}>
              {result1 === "要是正" && "○"}
            </Text>
          )}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!result2 && <Text style={styles.textTableCenter}>{"○"}</Text>}
        </View>

        <View style={TableColStyleFunc(5, 0, 1, 0, 1)}>
          {!!inve && <Text style={styles.textTableCenter}>{inve}</Text>}
        </View>
      </View>
    );
  }
};

export const createTableRow_100 = (title) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(100, 1, 1, 1, 1)}>
        <Text style={styles.textTableLeftBold}>{title ? title : "-"}</Text>
      </View>
    </View>
  );
};

export const createTableRow_100_A = (title) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(100, 1, 1, 0, 1)}>
        <Text style={styles.textTableLeftBold}>{title ? title : "-"}</Text>
      </View>
    </View>
  );
};

export const createTableRow_100_regular = (title) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(100, 1, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>{title ? title : "-"}</Text>
      </View>
    </View>
  );
};

export const createTableRow_5_25_30_30_10 = (
  result1,
  result2,
  result3,
  result4,
  result5
) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
        <Text style={styles.textTableCenter}>{result1 ? result1 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(25, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>{result2 ? result2 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(30, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>{result3 ? result3 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(30, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>{result4 ? result4 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(10, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>
          {result5
            ? `(${new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                era: "long",
                year: "numeric",
                month: "long",
              }).format(new Date(result5))})`
            : "-"}
        </Text>
      </View>
    </View>
  );
};

export const createTableRow_5_25_30_30_10_header = (
  result1,
  result2,
  result3,
  result4
) => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={TableColStyleFunc(5, 1, 1, 0, 1)}>
        <Text style={styles.textTableCenter}>{result1 ? result1 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(25, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>{result2 ? result2 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(30, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>{result3 ? result3 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(30, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>{result4 ? result4 : "-"}</Text>
      </View>

      <View style={TableColStyleFunc(10, 0, 1, 0, 1)}>
        <Text style={styles.textTableLeft}>改善(予定){"\n"}年月</Text>
      </View>
    </View>
  );
};

export const tableStyle_2 = {
  display: "table",
  width: "auto",
  marginTop: 2,
  marginLeft: 20,
  marginRight: 20,
};

export const tableStyle_18 = {
  display: "table",
  width: "auto",
  marginTop: 18,
  marginLeft: 20,
  marginRight: 20,
};

export const tableStyle_20 = {
  display: "table",
  width: "auto",
  marginTop: 20,
  marginLeft: 20,
  marginRight: 20,
};

const tableRowStyle = {
  flexDirection: "row",
};

const tableColumStyle = {
  flexDirection: "colum",
};

const TableColStyleFunc = (
  width,
  borderLeftWidth,
  borderRightWidth,
  borderTopWidth,
  borderBottomWidth
) => {
  return {
    width: 575 * width,
    borderStyle: "solid",
    borderColor: "#000",
    borderLeftWidth,
    borderRightWidth,
    borderTopWidth,
    borderBottomWidth,
  };
};

const TableColStyleFunc_P = (
  width,
  borderLeftWidth,
  borderRightWidth,
  borderTopWidth,
  borderBottomWidth
) => {
  return {
    width,
    borderStyle: "solid",
    borderColor: "#000",
    borderLeftWidth,
    borderRightWidth,
    borderTopWidth,
    borderBottomWidth,
  };
};
