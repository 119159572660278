import React from "react";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import { InputTextField, InputTextFieldMulti } from "../Parts/InputText";
import { InputSelect } from "../Parts/InputSelect";
import { InputRadio } from "../Parts/InputRadio";
import { InputCheakBox } from "../Parts/InputCheakBox";
import { InputDateMonth } from "../Parts/InputDate";

import GenericTemplate from "../GenericTemplate";
import { prefecture } from "../Prefecture/Prefecture";

const A1 = () => {
  const location = useLocation();
  return (
    <GenericTemplate
      title="第１面"
      type={"特殊建築物等"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <A11 />
        <A12 />
        <A13 />
        <A14 />
        <A15 />
      </Box>
    </GenericTemplate>
  );
};

export default A1;

export const A11 = React.memo(() => {
  return (
    <div>
      【１ 所有者】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a11_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a11_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a11_ha"} />
        </Grid>
        <Grid item xs={2}>
          ニ　住所
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a11_ni"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ホ　電話番号　
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextField key_num={"a11_ho"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const A12 = React.memo(() => {
  return (
    <div>
      【２ 管理者】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a12_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a12_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a12_ha"} />
        </Grid>
        <Grid item xs={2}>
          ニ　住所
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a12_ni"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ホ　電話番号　
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextField key_num={"a12_ho"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const A13 = React.memo(() => {
  return (
    <div>
      【３　調査者】
      <div>（代表となる調査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelect
            key_num={"a13_ai1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelect key_num={"a13_ai2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextField
            key_num={"a13_ai3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          特定建築物調査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextField
            key_num={"a13_ai4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_aro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_aha"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_ani1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelect
            key_num={"a13_ani2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextField key_num={"a13_ani3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            key_num={"a13_ani4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_aho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_ahe"} />
        </Grid>
        <Grid item xs={2}>
          ト　電話番号
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_ato"} />
        </Grid>
      </Grid>
      <div>（その他の調査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelect
            key_num={"a13_bi1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelect key_num={"a13_bi2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextField
            key_num={"a13_bi3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          特定建築物調査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextField
            key_num={"a13_bi4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_bro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_bha"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_bni1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelect
            key_num={"a13_bni2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextField key_num={"a13_bni3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            key_num={"a13_bni4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_bho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a13_bhe"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ト　電話番号
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextField key_num={"a13_bto"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const A14 = React.memo(() => {
  return (
    <div>
      【4　報告対象建築物】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a14_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　名称のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a14_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　名称
        </Grid>
        <Grid item xs={10}>
          <InputTextField key_num={"a14_ha"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ニ　用途
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextField key_num={"a14_ni"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const A15 = React.memo(() => {
  return (
    <div>
      【5　調査による指摘の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　指摘の内容
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={"a15_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBox key_num={"a15_i2"} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　指摘の概要
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldMulti key_num={"a15_ro"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ハ　改善予定の有無
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={"a15_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputDateMonth key_num1={"a15_ha2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 1 }}>
          ニ　その他特記事項
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldMulti key_num={"a15_ni"} />
        </Grid>
      </Grid>
    </div>
  );
});
