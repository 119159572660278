import React from "react";
import { useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  InputTextFieldEquip,
  InputTextFieldMultiEquip,
} from "../Parts/InputText";
import { InputRadioEquip } from "../Parts/InputRadio";
import { InputCheakBoxEquip } from "../Parts/InputCheakBox";
import { InputDateMonthEquip } from "../Parts/InputDate";

import GenericTemplate from "../GenericTemplate";

const E1 = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="第１面"
      type={"建築設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <E11 />
        <E12 />
        <E13 />
        <E14 />
      </Box>
    </GenericTemplate>
  );
};

export default E1;

export const E11 = React.memo(() => {
  return (
    <div>
      【１ 所有者】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e11_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e11_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e11_ha"} />
        </Grid>
        <Grid item xs={2}>
          ニ　住所
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e11_ni"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ホ　電話番号　
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldEquip key_num={"e11_ho"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const E12 = React.memo(() => {
  return (
    <div>
      【２ 管理者】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e12_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e12_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e12_ha"} />
        </Grid>
        <Grid item xs={2}>
          ニ　住所
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e12_ni"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ホ　電話番号　
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldEquip key_num={"e12_ho"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const E13 = React.memo(() => {
  return (
    <div>
      【3　報告対象建築物】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e13_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　名称のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e13_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　名称
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e13_ha"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ニ　用途
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldEquip key_num={"e13_ni"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const E14 = React.memo(() => {
  return (
    <div>
      【4　検査による指摘の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　指摘の内容
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e14_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBoxEquip key_num={"e14_i2"} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　指摘の概要
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldMultiEquip key_num={"e14_ro"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ハ　改善予定の有無
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e14_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputDateMonthEquip key_num1={"e14_ha2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 1 }}>
          ニ　その他特記事項
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldMultiEquip key_num={"e14_ni"} />
        </Grid>
      </Grid>
    </div>
  );
});
